import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import "../../src/assets/scss/Banner.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheck,
  faEnvelope,
  faLocationDot,
  faMobile,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../layouts/loader/Loader";
import { useSelector } from "react-redux";
import { GetData } from "../Utilities/Fetch/GetData";
import { useAuthToken } from "../Utilities/Auth/Token";
import { useDispatch } from "react-redux";
import {
  getCompanyProfile,
  updateCompanyProfile,
} from "../services/Actions/companyProfileSlice";
import FullName from "../Utilities/Reusable/FullName";
import {
  closeModalError,
  showModalError,
} from "../services/Actions/modalAction";
import ContentLoader, { List } from "react-content-loader";
import Config from "../Config/config";
import { LogoutAndClearProfile } from "../services/Actions/logoutAction";
import { handleAxiosError } from "../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { useSubscription } from "../hook/useSubscription";
import { SubscriptionFunction } from "../Utilities/Reusable/Function/Subscription/Subscription";
const Banner = () => {
  const apiKey = process.env.REACT_APP_CLIENT_API;
  const token = useAuthToken();
  const dispatch = useDispatch();

  const {
    lastName,
    firstName,
    middleName,
    address,
    contactNumber,
    email,
    profilePic,
    clientId,
  } =
    useSelector(
      (state) => state.reducer.companyProfile?.currentCompanyProfile
    ) || {};

  // const showErrorModal = useSelector((state) => state.reducer.modal.errorModal);
  // console.log(showErrorModal);
  const navigate = useNavigate();
  const [isProfileAvailable, setIsProfileAvailable] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isDataLoading, setisDataLoading] = useState(false);

  const [uploadError, setUploadError] = useState("");
  const [modalError, setModalError] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState("");
  const [showErrorModal, setShowModalError] = useState(false);
  const toggleModalErr = () => {
    localStorage.clear();
    setModalError(!modalError);
  };

  const modalLogout = () => {
    handleLogoutClick();
    setModalError(false);
  };

  const handleLogoutClick = async () => {
    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/client/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // localStorage.clear(); // Clear all local storage
      // const cevent = new CustomEvent("onChangeUserType", {
      //   detail: null,
      // });
      // document.dispatchEvent(cevent);

      dispatch(LogoutAndClearProfile());
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      dispatch(LogoutAndClearProfile());
      localStorage.clear();
      sessionStorage.clear();
      console.log(error);
      navigate("/");
    }
  };

  const up = () => {
    document.getElementById("upload-image").click();
  };

  const clickUploadPicture = async (event) => {
    const file = event.target.files[0];

    setisLoading(true);
    if (file) {
      if (file.size <= 5000000) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async function () {
          const maxWidth = 720;
          const maxHeight = 720;

          if (img.width <= maxWidth && img.height <= maxHeight) {
            await handleConfirmUpload(file);
          } else {
            setisLoading(false);

            setUploadError("Image dimensions exceed the limit of 720px.");

            setTimeout(() => {
              setUploadError(null);
            }, 5000);
          }
        };
      } else {
        setisLoading(false);

        setUploadError("File size exceeds the limit of 5MB.");

        setTimeout(() => {
          setUploadError(null);
        }, 5000);
      }
    } else {
      setisLoading(false);
      setUploadError("No file selected.");

      setTimeout(() => {
        setUploadError(null);
      }, 5000);
    }
  };

  const handleConfirmUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadProfile = await axios.post(
        `${Config.nxtApi}/api/client/uploadprofile`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      // console.log("Upload Profile Res : ", uploadProfile);
      if (uploadProfile.data.code === "0") {
        const payload = { fileName: uploadProfile.data.fileName };
        dispatch(
          updateCompanyProfile({ profilePic: uploadProfile.data.fileName })
        );
        const uploadProfilePic = await axios
          .post(`${Config.nxtApi}/api/client/updateprofilepic`, payload, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            if (response.data.code === "0") {
              setIsProfileAvailable(
                `${apiKey}/profile-pic?fileName=${encodeURIComponent(
                  payload.fileName
                )}`
              );
            } else {
              console.log("err update profile pic");
            }
            setisLoading(false);
          })
          .catch((err) => {
            setisLoading(false);
            console.log("/updateprofilepic Err: ", err);
          });

        // updateProfile();
      } else {
        setisLoading(false);
        console.log("err");
      }
    } catch (error) {
      setisLoading(false);
      if (error.response.status === 400) {
        setUploadError(error.response.data.message);
      }
      console.log("Error uploading profile picture:", error);
    }
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   const fetchData = async () => {
  //     setisDataLoading(true);
  //     try {
  //       setShowModalError(false);

  //       const data = await GetData("api/client/myprofile", token);

  //       if (isMounted) {
  //         if (data && data.data && data.data.length > 0) {
  //           const profilePicUrl = profilePic
  //             ? `${apiKey}/profile-pic?fileName=${encodeURIComponent(
  //                 profilePic
  //               )}`
  //             : null;

  //           const profileData = {
  //             ...data.data[0],
  //             isProfileAvailable: profilePicUrl,
  //           };

  //           dispatch(getCompanyProfile(profileData));
  //           setisDataLoading(false);
  //         } else {
  //           console.log("first");
  //           setisDataLoading(false);
  //           console.log(data);
  //           setModalErrorMsg(data.message || data || "An error occurred.");
  //           setShowModalError(true);
  //           handleAxiosError(data);
  //         }
  //       }
  //     } catch (error) {
  //       if (isMounted) {
  //         setisDataLoading(false);
  //         // console.error(error);
  //         setModalErrorMsg("An error occurred.");
  //         // dispatch(showModalError());
  //       }
  //     }
  //   };

  //   fetchData();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "17px";
    } else {
      // Revert body styles when modal is closed
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    }

    // Cleanup function to reset body styles when component unmounts or modal is closed
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    };
  }, [isModalOpen]);

  useEffect(() => {
    setIsProfileAvailable(
      `${Config.nxtApi}/api/client/profile-pic?fileName=${encodeURIComponent(
        profilePic
      )}`
    );
  }, [profilePic]);

  const { checkSubscription, checkSubsData } = useSubscription();

  useEffect(() => {
    checkSubscription(clientId);
  }, [clientId]);

  const { getPlanName } = SubscriptionFunction();

  return (
    <div className="background-banner">
      <div className="background-overlay">
        {isDataLoading ? (
          <Container className="list">
            <ContentLoader
              speed={2}
              width={400}
              height={160}
              viewBox="0 0 400 160"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
              <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
              <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
              <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              <circle cx="20" cy="20" r="20" />
            </ContentLoader>
          </Container>
        ) : (
          <>
            <Container className="banner-container">
              <div className="upload-image-container-outer">
                <div className="upload-image-container">
                  <input
                    id="upload-image"
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    hidden
                    onChange={clickUploadPicture}
                  ></input>
                  <div className="banner-image-bg" onClick={up}>
                    {encodeURIComponent(profilePic) === "undefined" ? (
                      <>
                        <FontAwesomeIcon
                          className="banner-icon-user"
                          icon={faUser}
                        />
                      </>
                    ) : (
                      <>
                        {isLoading ? (
                          <>
                            <Loader />
                          </>
                        ) : (
                          <>
                            <img
                              className="banner-profile"
                              crossOrigin="anonymous"
                              src={isProfileAvailable}
                            ></img>
                          </>
                        )}
                      </>
                    )}{" "}
                  </div>
                  <div className="banner-camera-bg" onClick={up}>
                    <FontAwesomeIcon
                      className="banner-icon-camera"
                      icon={faCamera}
                    />
                  </div>
                </div>
                <p style={{ marginBottom: "0" }}>Photo Size sample 720 x 720</p>
                <div
                  className="error-msg"
                  style={{ margin: "10px 0", textAlign: "center" }}
                >
                  {uploadError}
                </div>
              </div>

              <div className="banner-profile-information">
                <div>
                  <h1>
                    <FullName
                      lastName={lastName}
                      firstName={firstName}
                      middleName={middleName}
                    />
                  </h1>
                  <div className="banner-user-info">
                    <div>
                      <p>
                        <FontAwesomeIcon
                          className="banner-user-info-icon"
                          icon={faLocationDot}
                        />
                        {address}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="banner-user-info-icon"
                        />
                        Client
                      </p>
                    </div>
                    <div>
                      <p>
                        <FontAwesomeIcon
                          icon={faMobile}
                          className="banner-user-info-icon"
                        />
                        {contactNumber}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="banner-user-info-icon"
                        />
                        {email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-freemium-btn">
                <Button className="freemium-btn">
                  <h3 className="m-0">
                    {getPlanName(
                      checkSubsData?.[0]?.activeSubscription?.current
                    )}{" "}
                    Subscription
                  </h3>

                  {/* <div className="freemium-btn-desc">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="banner-user-info-icon"
                    />
                    <p>Unlimited Job Posting</p>
                  </div> */}
                </Button>
              </div>
            </Container>{" "}
          </>
        )}
      </div>
      {/* <WarningModal
        modalOpen={showErrorModal}
        toggleModal={toggleModalErr}
        modalLabel="Warning"
        modalSublabel={modalErrorMsg}
        confirmButtonText="Confirm"
        onConfirm={modalLogout}
      /> */}
    </div>
  );
};

export default Banner;
