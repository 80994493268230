import React, { useEffect, useState } from "react";
import "../../assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import BannerJobListDetails from "../../components/LayoutTalent/BannerJobListDetails";
import SidebarJobList from "../../components/LayoutTalent/JobList/SidebarJobList";
import JobListSidebar from "../../components/LayoutTalent/JobList/JobListDetails/JobListSidebar";
import JobListContent from "../../components/LayoutTalent/JobList/JobListDetails/JobListContent";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import { useParams } from "react-router-dom";
import { useTalent } from "../../hook/useTalent";
import { List } from "react-content-loader";
import Footer from "../LandingPage/Components/Footer";

const JobListDetailsPageTalent = () => {
  const { id } = useParams();
  const getJobPayload = JSON.parse(localStorage.getItem("jobPayload"));
  const { viewJobDetails, viewJobData, viewJobDetailsLoad } = useTalent();
  const [jobDetails, setJobDetails] = useState([]);
  console.log(jobDetails, viewJobData);
  useEffect(() => {
    viewJobDetails(getJobPayload.id, getJobPayload.job_id);
  }, [id]);

  useEffect(() => {
    if (viewJobData) {
      setJobDetails(viewJobData);
    }
  }, [viewJobData]);

  TabTitle("NXT | Job List Details");

  return (
    <div className="background-imagePost">
      <TalentAnalytics />{" "}
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      {jobDetails.length === 0 ? (
        <List />
      ) : (
        <>
          <div className="jobposting-banner">
            <BannerJobListDetails jobDetails={jobDetails} />{" "}
          </div>
          <Container className="jobposting-body">
            <div>
              <div className="sidebar-container">
                <JobListSidebar jobDetails={jobDetails} />
              </div>
            </div>

            <div className="company-profile-container">
              <JobListContent jobDetails={jobDetails} />
            </div>
          </Container>
        </>
      )}
      <Footer />
    </div>
  );
};

export default JobListDetailsPageTalent;
