import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import ManageJobFormPagination from "./ManageJobFormPagination";
import ManageJobFormTable from "./ManageJobFormTable";
import axios from "axios";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import CustomModal from "../../../../../components/UI/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutAndClearProfile } from "../../../../../services/Actions/logoutAction";
import { handleAxiosError } from "../../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
const ManageJobForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [allError, setAllError] = useState("");
  const token = useAuthToken();
  const devBase = localDevBase();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(`${devBase}/api/ats/manage-job`, {
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        // withCredentials: true,
      });
      const result = response?.data?.result;
      const allJobs = result?.data;

      const filteredJobs = allJobs.filter(
        (job) =>
          job.jobTitle.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          job.jobUUID.toLowerCase().includes(searchKeyword.toLowerCase())
      );

      setJobs(filteredJobs);
    } catch (error) {
      console.error("Error fetching data:", error);
      // handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const reloadData = async () => {
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [searchKeyword]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columnsPerPage = 10;
  const startIndex = (currentPage - 1) * columnsPerPage;
  const endIndex = Math.min(startIndex + columnsPerPage, jobs.length);
  const visibleJobs = jobs.slice(startIndex, endIndex);

  const jobCount = jobs.length;

  const handleLogout = () => {
    setModalShow(false);
    setAllError("");
    dispatch(LogoutAndClearProfile());
    localStorage.clear();
    sessionStorage.clear();
    console.log("Logout ...");
    navigate("/");
  };

  return (
    <>
      <h3 className="manage-job__main_title border-bottom pb-3 mb-4">
        Manage Job
      </h3>
      <Form>
        <div style={{ minHeight: "690px" }}>
          <ManageJobFormTable
            jobs={visibleJobs}
            columnsPerPage={columnsPerPage}
            setSearchKeyword={setSearchKeyword}
            setJobs={setJobs}
            reloadData={reloadData}
            loadTable={loading}
          />
        </div>
        <ManageJobFormPagination
          jobs={jobs}
          columnsPerPage={columnsPerPage}
          count={jobCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageJobs={setJobs}
        />
      </Form>
      <CustomModal
        show={modalShow}
        modalMessage={
          allError === "Error"
            ? "You have been automatically logged out"
            : "Something Went Wrong"
        }
        modalType={"Error"}
        onHide={() => setModalShow(false)}
        buttonName="Confirm"
        onClick={handleLogout}
      />
    </>
  );
};

export default ManageJobForm;
