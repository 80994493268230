import React, { useEffect, useState } from "react";
import "../../../../assets/scss/CompanyProfile.scss";
import { useForm, FormProvider } from "react-hook-form";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import ContactPerson from "./ContactPerson";
import CompanyProfileDetails from "./CompanyProfileDetails";
import { Button } from "react-bootstrap";
import Loader from "../../../../layouts/loader/Loader";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import {
  failCompanyProfile,
  updateCompanyProfile,
} from "../../../../services/Actions/companyProfileSlice";
import { PostData } from "../../../../Utilities/Post/Post";
import { useSelector } from "react-redux";
import { List } from "react-content-loader";
import { CompanyProfileSchema } from "../../../../Utilities/Reusable/Validation/Schema/CompanyProfileSchema";
import CustomModal from "../../../../components/UI/Modal";
import RequiredModal from "../../../../components/RequiredModal";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAxiosError } from "../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { SubscriptionFunction } from "../../../../Utilities/Reusable/Function/Subscription/Subscription";
import subsciption from "../../../../Data/subscription/subscription.json";
import { useSubscription } from "../../../../hook/useSubscription";

const CompanyProfileForm = () => {
  const token = useAuthToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { checkSubscription, checkSubsData } = useSubscription();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [hasUpdateError, setHasUpdateError] = useState(false);
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);

  const [updateMsg, setUpdateMsg] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const location = useLocation();
  const companyProfile = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile
  );

  const openMandatoryModal = () => {
    setModalOpen5(true);
  };

  // Function to close the "Please Update" modal
  const closeMandatorymodal = () => {
    setModalOpen5(false);
  };

  const { getURLSubsPlanPrice, feature_pricing } = SubscriptionFunction();
  useEffect(() => {
    getURLSubsPlanPrice();
  }, []);

  function checkIfProfileHasEmptyProps(profile) {
    if (profile != null && Object.keys(profile).length !== 0) {
      for (const property in profile) {
        if (
          profile[property] == null ||
          profile[property] === "" ||
          profile[property] === "N/A"
        )
          return false;
      }
      return true;
    }
    return false;
  }

  // useForm
  const methods = useForm({
    resolver: zodResolver(CompanyProfileSchema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (companyProfile) {
      const newInitialValues = {
        companyName:
          companyProfile?.companyName === "N/A"
            ? ""
            : companyProfile?.companyName,
        companySize:
          companyProfile?.companySize === "N/A"
            ? ""
            : companyProfile?.companySize,
        industry:
          companyProfile?.industry === "N/A" ? "" : companyProfile?.industry,
        address:
          companyProfile?.address === "N/A" ? "" : companyProfile?.address,
        country:
          companyProfile?.country === "N/A"
            ? "Philippines"
            : companyProfile?.country,
        region:
          companyProfile?.region === undefined ||
          companyProfile?.region === "N/A"
            ? ""
            : companyProfile?.region,
        postal: companyProfile?.postal === "N/A" ? "" : companyProfile?.postal,
        firstName:
          companyProfile?.firstName === "N/A" ? "" : companyProfile?.firstName,
        email: companyProfile?.email === "N/A" ? "" : companyProfile?.email,
        lastName:
          companyProfile?.lastName === "N/A" ? "" : companyProfile?.lastName,
        middleName:
          companyProfile?.middleName === "N/A"
            ? ""
            : companyProfile?.middleName,
        contactNumber:
          companyProfile?.contactNumber === "N/A"
            ? "+63"
            : companyProfile?.contactNumber,
      };
      methods.reset(newInitialValues);
      setInitialValues(newInitialValues);
      // setInitialValuesLoaded(true);
    }
  }, [companyProfile]);

  useEffect(() => {
    if (Object.keys(initialValues).length > 0) {
      const profileData = initialValues;
      delete profileData?.middleName;
      const validated = checkIfProfileHasEmptyProps(profileData);

      // Only open the modal if on the /client/company-profile route
      if (
        location.pathname.includes("/client/company-profile") &&
        !initialValuesLoaded
      ) {
        setInitialValuesLoaded(true);
        setTimeout(() => {
          setModalOpen5(!validated);
        }, 2000);
      }
    }
  }, [initialValues, initialValuesLoaded, location.pathname]);

  // Submit
  const onSubmit = (data) => {
    setIsLoading(true);
    updateProfile(data);
  };

  // Updating Profile
  const updateProfile = async (data) => {
    console.log(data);
    try {
      const response = await PostData("api/client/updateprofile", data, token);
      if (response.code === "0") {
        setUpdateMsg(
          "Your profile has been updated successfully. All changes have been saved."
        );
        dispatch(updateCompanyProfile(data));
        setShowModal(true);
        setIsLoading(false);
      } else {
        dispatch(failCompanyProfile());
        setUpdateMsg(response.message);
        setHasUpdateError(true);
        setShowModal(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      handleAxiosError(error);
    }
  };

  console.log(feature_pricing);

  const userType = useSelector(
    (state) => state.reducer.user?.currentUser?.userType
  );

  const feature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.isSubscribed
  );

  const clientId = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.clientId
  );

  console.log(feature);
  const handleClickClientLogin = () => {
    const profileData = initialValues;
    delete profileData?.middleName;
    const validated = checkIfProfileHasEmptyProps(profileData);

    if (userType === "client") {
      console.log(feature_pricing, validated);
      if (feature_pricing && validated) {
        console.log("!validated");
        navigate("/client/subscription");
      } else {
        console.log("!validate");
        setShowModal(false);
        navigate("/client/company-profile");
      }
    } else {
      navigate("/client/company-profile");
    }
  };

  const handleCheckSubscription = async () => {
    await checkSubscription(clientId);
  };

  useEffect(() => {
    handleCheckSubscription();
  }, []);

  return (
    <div className="update-profile-container">
      <div className="company-profile">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {companyProfile === undefined ? (
              <List />
            ) : (
              <>
                <CompanyProfileDetails />
                <div className="contact-person-container">
                  <ContactPerson />
                </div>
                <div className="profile-update-btn">
                  <Button className="update-btn" type="submit">
                    {isLoading ? <Loader /> : "Update Setting"}
                  </Button>
                </div>
              </>
            )}
          </form>
        </FormProvider>
      </div>
      <CustomModal
        modalMessage={updateMsg}
        buttonName="Close"
        show={showModal}
        modalType={hasUpdateError ? "Warning" : "Success"}
        onClick={
          hasUpdateError
            ? () => setShowModal(false)
            : () => handleClickClientLogin()
        }
      />
      <RequiredModal
        modalOpen={modalOpen5}
        toggleModal={openMandatoryModal}
        modalLabel="Please Update"
        modalSublabel="Take the next step in finding top talents. Complete the essential fields and start your journey with us."
        confirmButtonText="OK"
        onConfirm={closeMandatorymodal}
      />
    </div>
  );
};

export default CompanyProfileForm;
