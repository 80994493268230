import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import { Nav, Tab } from "react-bootstrap";
import SubsCardPro from "../../../../components/LayoutClient/Subscription/SubscriptionCard/SubsCardPro";
import "../../../../assets/scss/Page/Admin/Subscription/_subscriptionCard.scss";
import SubsCardPremium from "../../../../components/LayoutClient/Subscription/SubscriptionCard/SubsCardList";
import { useSubscription } from "../../../../hook/useSubscription";
import { useSelector } from "react-redux";
import SubscriptionCardContainer from "../../../../components/LayoutClient/Subscription/Subscription";
import { List } from "react-content-loader";
import ActiveSubscription from "./Active/ActiveSubscription";
import AvailableAddOns from "./AddOn/AddOnList/AvailableAddOns";
const SubscriptionAts = () => {
  const clientId = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.clientId
  );

  const { checkSubscription, checkSubsData } = useSubscription();
  console.log(checkSubsData);
  const [isLoading, setIsLoading] = useState(false);
  const selectedPlan = {
    planName: "Premium",
    amount: 1000,
    value: 3,
    month: 1,
    description: [
      { isBullet: false, text: "Unlimited Job Postings" },
      { isBullet: false, text: "Basic Applicant Tracking System" },
      { isBullet: false, text: "Downloadable CV" },
      { isBullet: false, text: "Customer Support (email, call)" },
    ],
  };

  const sampData = [
    {
      activeSubscription: {
        current: "premium",
        pendingPlan: null,
        daysLeft: 23,
      },
      addOn: {
        current: null,
        pendingPlan: null,
      },
    },
  ];

  const handleCheckSubscription = async () => {
    await checkSubscription(clientId);
  };

  useEffect(() => {
    handleCheckSubscription();
  }, [clientId]);

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer subscription-card__ats">
          <WhiteContainer>
            <Tab.Container id="tabs-example" defaultActiveKey="first">
              <Nav variant="underline">
                <Nav.Item>
                  <Nav.Link eventKey="first">Active</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="second">Add On</Nav.Link>
                </Nav.Item> */}
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {isLoading ? (
                    <List />
                  ) : (
                    <div className="content-container">
                      {checkSubsData && (
                        <ActiveSubscription activePlan={checkSubsData} />
                      )}
                    </div>
                  )}
                  {/* Your content for Tab 1 */}
                </Tab.Pane>
                {/* <Tab.Pane eventKey="second">
                  <div className="content-container">
                    {checkSubsData && (
                      <AvailableAddOns activePlan={checkSubsData} />
                    )}
                  </div>
                </Tab.Pane> */}
                <Tab.Pane eventKey="third">
                  {/* Your content for Tab 3 */}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAts;
