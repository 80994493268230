import React from "react";

const PlanAmount = ({ planName, amount }) => {
  return (
    <>
      <h2 className="container--subs__card--plan text-center my-2 fw-bold">
        {planName}
      </h2>
      <div className="container--subs__card--amount d-flex justify-content-center">
        <h5 className="me-2">₱</h5>
        <h2 className="display-4"> {amount.toLocaleString()}</h2>
      </div>
      <p className="text-center">per month</p>
    </>
  );
};

export default PlanAmount;
