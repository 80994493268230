import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import "../assets/scss/Modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const SuccessModal = ({
  modalOpen,
  toggleModal,
  iconImage,
  modalLabel,
  modalSublabel,
  confirmButtonText,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      keyboard={false}
      className="custom-modal-container"
      centered
    >
      <ModalBody className="custom-modal-body">
        <div className="icon-container">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="success-icon"
            onClick={onCancel}
          />
        </div>
        {/* <img src={iconImage} alt="Icon" className="icon" /> */}
        <p className="modal-title">{modalLabel}</p>
        <p className="modal-sublabel">{modalSublabel}</p>
        <Button color="primary" onClick={onConfirm} className="success-btn">
          {confirmButtonText}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
