import React, { useState } from "react";
import SourcingSelection from "./CandidateList/SourcingSelection/SourcingSelection";
import AdminSidebar from "../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../layouts/Navbar";
import SubscriberCard from "../../../components/UI/Card/SubscriberCard";
import WhiteContainer from "../../../layouts/Container/WhiteContainer";
import SubscriberCardInfo from "./SubscriberCardInfo";
import { useLocation } from "react-router";
import CustomModal from "../../../components/UI/Modal";

const ATS = () => {
  const [statisticsData, setStatisticsData] = useState({
    inProgressLength: 0,
    hiredLength: 0,
    failedLength: 0,
  });
  const location = useLocation();
  const subscriberData = location.state;

  const additionalComponents = (
    <SubscriberCardInfo
      subscriberData={subscriberData}
      statisticsLength={statisticsData}
    />
  );

  const applicantMatchResBody = {
    createdAt: "2024-04-23T08:25:04.128Z",
    content: [
      {
        applicantId: "3c45e86e-98d1-98d1-4cc9-f172fd3c4573",
        firstName: "michael",
        lastName: "stone",
        skills: [
          "regulation filing",
          "statistical analysis",
          "decision making",
        ],
        experience: 4,
        ifSubs: 0,
        overallScore: 75.33,
        workHistoryScore: 76,
        skillsScore: 58,
        educationScore: 92,
      },
      {
        applicantId: "98d1e86e-98d1-98d1-4cc9-f172fd3c4573",
        firstName: "ludwig",
        lastName: "topaz",
        skills: [
          "regulation filing",
          "statistical analysis",
          "decision making",
        ],
        experience: 4,
        ifSubs: 1,
        overallScore: 70,
        workHistoryScore: 80,
        skillsScore: 70,
        educationScore: 60,
      },
      {
        applicantId: "f172e86e-98d1-98d1-4cc9-f172fd3c4573",
        firstName: "gabriel",
        lastName: "ruby",
        skills: ["accounting", "spreadsheet", "cpa"],
        experience: 4,
        ifSubs: 0,
        overallScore: 63,
        workHistoryScore: 45,
        skillsScore: 63,
        educationScore: 81,
      },
      {
        applicantId: "3c45e86e-98d1-98d1-4cc9-f172fd3c4573",
        firstName: "raphael",
        lastName: "jade",
        skills: ["tax analysis", "business management", "financial advisory"],
        experience: 4,
        ifSubs: 0,

        overallScore: 60,
        workHistoryScore: 37,
        skillsScore: 54,
        educationScore: 89,
      },
      {
        applicantId: "4573e86e-98d1-98d1-4cc9-f172fd3c4573",
        firstName: "marcus",
        lastName: "sapphire",
        skills: ["book-keeping", "accounting", "documenting"],
        experience: 4,
        ifSubs: 1,
        overallScore: 85,
        workHistoryScore: 72,
        skillsScore: 85,
        educationScore: 98,
      },
    ],
  };

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer">
          <WhiteContainer>
            <SubscriberCard
              subscriberData={subscriberData}
              additionalComponents={additionalComponents}
            />
          </WhiteContainer>
          <SourcingSelection
            item={applicantMatchResBody}
            statistics={setStatisticsData}
            stats={statisticsData}
            clientData={subscriberData}
          />
        </div>
      </div>
      {/* <CustomModal
        show={modalShow}
        modalMessage={""}
        modalType={""}
        onHide={() => setModalShow(false)}
        buttonName="Confirm"
        onClick={setModalShow(false)}
      /> */}
    </div>
  );
};

export default ATS;
