import {
  faPaperPlane,
  faTrash,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";
import Config from "../../../Config/config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { getAI } from "../../../services/Actions/aiAction";
import logo from "../../../assets/images/Icon/userIcon.png";

const ChatAssistant = () => {
  const dispatch = useDispatch();
  const [chatText, setChatText] = useState("");
  const [messages, setMessages] = useState([]);
  const [hasMessage, setHasMessage] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [error15s, setError15s] = useState(false);
  const [isProfileAvailable, setIsProfileAvailable] = useState(null);
  const [loadChat, setLoadChat] = useState(false);
  const talentPic = useSelector(
    (state) => state.reducer.profile.currentProfile.profilePic
  );

  // console.log(talentPic);

  // console.log(messages);
  const chatBoxRef = useRef(null);

  const scrollToBottom = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };

  const handleDeleteInputText = () => {
    setChatText("");
  };

  const userInfo = useSelector((state) => state.reducer.user.currentUser);
  const aiInfo = useSelector((state) => state.reducer.aimlInfo.aiml);

  // console.log(userInfo);
  /**
   * post
   * api - /api/chat/initiate
   */

  const initiateChat = async () => {
    const initiateReqPayload = {
      userId: userInfo.userId,
      roleId: "",
      userType: "talent",
      platform: "NXT",
    };

    const previousDate = moment().subtract(1, "days");

    try {
      const response = await RequestMethod(
        "post",
        "ai",
        "/api/chat/initiate",
        initiateReqPayload,
        null,
        null,
        true
      );

      dispatch(
        getAI({
          sessionId: response.data.sessionId,
          msgCount: 25,
          date: moment().toISOString(),
          error: null,
        })
      );
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  /**
   * post
   * api - /api/chat/response
   */
  const sendMessage = async () => {
    if (!chatText.trim()) return;
    setError15s("");
    setLoadChat(true);

    const chatReqPayload = {
      sessionId: aiInfo.sessionId,
      dialogueType: "register",
      createdAt: moment().toISOString(),
      content: {
        text: chatText.trim(),
        isRichText: false,
      },
    };

    const newMessage = {
      content: {
        text: chatText.trim(),
      },

      dialogueType: "register",
      isRichText: false,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    setChatText("");

    // Reusable and text included
    let timeout;
    try {
      // const response = await RequestMethod(
      //   "post",
      //   "ai",
      //   "/api/chat/response",
      //   chatReqPayload,
      //   null,
      //   null,
      //   { withCredentials: true }
      // );

      const responsePromise = await axios.post(
        `${Config.ai}/api/chat/response`,
        chatReqPayload,
        { withCredentials: true }
      );

      console.log(responsePromise);

      const errorResponse = {
        content: {
          text: "I’m sorry, I’m having trouble processing your inquiry. Please try asking again or type a new command.",
        },
        dialogueType: "response",
        isRichText: false,
      };

      timeout = setTimeout(() => {
        setMessages(errorResponse);
        console.log("timeout");
        throw new Error("Request timed out");
      }, 30000);

      const response = await Promise.race([
        responsePromise,
        new Promise((_, reject) => setTimeout(reject, 30000)),
      ]);

      clearTimeout(timeout);

      console.log(response.data);

      const resMessage = {
        content: {
          text: response.data.content.text,
        },

        dialogueType: response.data.dialogueType,
        isRichText: response.data.content.isRichText,
      };

      setMessages((prevMessages) => [...prevMessages, resMessage]);

      setChatText("");

      setLoadChat(false);
      setError15s("");
    } catch (error) {
      setErrorMsg(true);
    }
  };

  const getChatHistory = async () => {
    // /api/chat/history

    try {
      const response = await RequestMethod(
        "get",
        "ai",
        "/api/chat/history",
        null,
        null,
        null,
        { withCredentials: true }
      );
      console.log(response);
      setMessages(response.data.dialogue);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (aiInfo?.error === true || !aiInfo?.sessionId) {
      initiateChat();
      return;
    }

    getChatHistory();
  }, [userInfo.userId, aiInfo?.error]);

  console.log(talentPic);
  useEffect(() => {
    setIsProfileAvailable(
      `${Config.nxtApi}/api/talent/profile-pic?fileName=${encodeURIComponent(
        talentPic
      )}`
    );
  }, [talentPic]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div className="chat-assistant">
        <div className="chat-assistant_box position-relative" ref={chatBoxRef}>
          {messages.length > 0 ? (
            messages.map((message, index) => (
              <>
                <div
                  key={index}
                  className={`${
                    message.dialogueType === "register"
                      ? "chat-assistant__talent-bestmatch d-flex justify-content-end mb-3"
                      : "chat-assistant__ai d-flex mb-3"
                  }`}
                >
                  {message.dialogueType !== "register" && (
                    <div className="chat-assistant__ai-logo"></div>
                  )}
                  <div
                    className={`${
                      message.dialogueType === "register"
                        ? "chat-assistant__talent-text"
                        : "chat-assistant__ai-text"
                    }`}
                  >
                    <div
                      className={`${
                        message.dialogueType === "register"
                          ? "triangle-right"
                          : "triangle-left"
                      }`}
                    ></div>
                    {message.isRichText ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: message.content.text,
                        }}
                      ></p>
                    ) : (
                      message.content.text
                    )}
                  </div>
                  {message.dialogueType === "register" && (
                    <div className="chat-assistant__talent-logo">
                      {encodeURIComponent(talentPic) === "undefined" ? (
                        <>
                          <FontAwesomeIcon
                            className="chat-assistant__talent-logo-img"
                            icon={faUserCircle}
                          />
                        </>
                      ) : (
                        <img
                          className="chat-assistant__talent-logo-img"
                          crossOrigin="anonymous"
                          src={isProfileAvailable}
                          onError={(e) => {
                            if (talentPic === undefined) {
                              e.target.classList.remove("errorImg");
                            } else {
                              e.target.src = logo;
                              e.target.classList.add("errorImg");
                            }
                          }}
                        ></img>
                      )}
                    </div>
                  )}
                </div>
              </>
            ))
          ) : (
            <div className="position-absolute top-50 start-50 translate-middle text-center">
              <h3>NXT, Client.</h3>
              <h4>How can I help you today?</h4>
            </div>
          )}

          {loadChat && (
            <div className="chat-assistant__loader position-absolute"></div>
          )}

          {errorMsg && (
            <div className="chat-assistant__error position-absolute mx-5">
              There was an error generating response
            </div>
          )}

          {/* {error15s && (
            <div className="chat-assistant__error position-absolute mx-5">
              I’m sorry, I’m having trouble processing your inquiry. Please try
              asking again or type a new command.
            </div>
          )} */}
        </div>
        <Form
          className="mb-3 position-relative"
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage();
          }}
        >
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="How can we help?"
            value={chatText}
            onChange={(e) => setChatText(e.target.value)}
          />
          <button className="trash" onClick={handleDeleteInputText}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button type="submit">
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </Form>
      </div>
    </>
  );
};

export default ChatAssistant;
