import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import CustomCheckbox from "../../../Form/CustomCheckbox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import Config from "../../../../Config/config";
import { useSelector } from "react-redux";
import Loader from "../../../../layouts/loader/Loader";
import { useSubscription } from "../../../../hook/useSubscription";

const SubsPaymentMethod = ({ count, typeOfSub, selectedPlan }) => {
  const { subscribePlan } = useSubscription();

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const navigate = useNavigate();
  const token = useAuthToken();

  const handleGoBack = () => {
    window.history.back(); // Navigates back in the browser's history
  };

  // const handleSubscriptionSubscribed = () => {
  //   navigate("/client/subscription-subscribed");
  // };

  const clientInfo = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile
  );
  // console.log(clientInfo);

  const handlePlaceHolderActivate = async () => {
    // setIsLoading(true);
    const data = {
      customerId: clientInfo.clientId,
      items: [
        {
          planId: selectedPlan.planId,
          count: ["ai", "ats+ai", "ats"].includes(selectedPlan.planName)
            ? 1
            : Number(count),
        },
      ],
      emailInfo: {
        clientName: `${
          clientInfo.firstName +
          " " +
          (clientInfo.middleName ? clientInfo.middleName : "") +
          " " +
          clientInfo.lastName
        }`,
        companyName: clientInfo.companyName,
        contactNumber: clientInfo.contactNumber,
        clientEmail: clientInfo.email,
      },
    };

    await subscribePlan(data);

    //   const response = await axios.post(
    //     `${Config.subs}/api/v1/subscription/order`,
    //     data
    //   );

    //   setIsLoading(false);
    //   console.log(response);
    //   navigate("/client/subscription-subscribed", {
    //     state: { typeOfSub: typeOfSub },
    //   });
    // } catch (error) {
    //   setIsLoading(false);
    //   if (error.response.status === 504) {
    //     navigate("/client/subscription-subscribed");
    //   } else {
    //     alert("client is unable subscribe to ats");
    //     console.log(error);
    //   }
    // }
    // navigate("/client/subscription-select", { state: { subCard: "basic" } });
  };

  return (
    <>
      <Col md={12}>
        <div className="checkout-page checkout-page__mod bg-white p-4 mt-5">
          <h2 className="border-bottom pb-4 mb-4">
            Choose Your Payment Method
          </h2>
          <Form>
            <div className="d-flex checkout-page__pay">
              <Form.Check
                className="me-2"
                type="radio"
                aria-label="radio 1"
                checked
              />
              Pay with Bank Deposit
            </div>
            <div className="text-center">
              <small>
                <CustomCheckbox
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  link="/terms-and-condition"
                  linkName="Terms and Conditions"
                  label="Accept"
                  // labelAfter="and Terms"
                  linkClassName="noUnderline"
                  className="mb-5"
                />
              </small>
              <div className="d-flex justify-content-center gap-3">
                <Button
                  className="btn btn-light py-3 px-5 border-0"
                  onClick={handleGoBack}
                >
                  BACK
                </Button>
                <Button
                  className="btn btn-green py-3 px-5 border-0 me-2"
                  disabled={!isChecked || isLoading}
                  onClick={handlePlaceHolderActivate}
                >
                  {isLoading ? <Loader /> : "PLACE ORDER"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default SubsPaymentMethod;
