import axios from "axios";
import React, { useEffect, useState } from "react";
import Config from "../../../../Config/config";

import "../../../../assets/scss/Page/Admin/Dashboard/_recentCompany.scss";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RecentCompany = () => {
  ///api/{version}/admin/dashboard/company/recently

  const token = localStorage.getItem("refreshToken");

  const [recentCompany, setRecentCompany] = useState([]);
  useEffect(() => {
    getRecentCompany();
  }, []);

  const getRecentCompany = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${Config.admin}/api/v1/admin/dashboard/company/recently`,
        {
          headers: headers,
        }
      );

      setRecentCompany(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="companyList">
      <h1>Recently Added Company</h1>
      {recentCompany.map((value, index) => {
        return (
          <div key={index} className="companyListInner">
            <div className="companyListcontent">
              <div className="companyListImgContainer">
                {!value.profilePic || value.profilePic === "N/A" ? (
                  <FontAwesomeIcon icon={faBuilding} className="icon" />
                ) : (
                  <img
                    crossOrigin="anonymous"
                    src={`${
                      Config.nxtApi
                    }/api/client/profile-pic?fileName=${encodeURIComponent(
                      value.profilePic
                    )}&thumbNail=yes`}
                    alt="logo"
                  ></img>
                )}
              </div>
              <h1>{value.companyName}</h1>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecentCompany;
