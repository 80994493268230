import React from "react";
import { Button } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const MatchScore = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-5 d-flex">
          <CircularProgressbar
            value={data.overallScore}
            text={`${data.overallScore}%`}
            className="screen-score__circle"
            styles={buildStyles({
              rotation: 0,
              strokeLinecap: "butt",
              textSize: "16px",
              pathTransitionDuration: 0.5,
              pathColor: `rgba(62, 152, 199, 1)`,
              textColor: "#30adf3",
              trailColor: "#d6d6d6",
              backgroundColor: "#3e98c7",
            })}
          />
          <div className="px-2">
            <h5>AI Match Score</h5>
            <p>by Skills, Working History, Education</p>
          </div>
        </div>
        <div className="col-md-5">
          <div className="w-100 d-flex mb-2">
            <small className="w-25 me-2 text-nowrap">Work History</small>
            <div className="w-50">
              <div
                className="progress h-100 me-2"
                // role="progressbar"
                // aria-label="Example with label"
                // aria-valuenow="25"
                // aria-valuemin="0"
                // aria-valuemax="100"
              >
                <div
                  class="progress-bar"
                  style={{ width: `${data.workHistoryScore}%` }}
                ></div>
              </div>
            </div>
            <div className="w-25">{data.workHistoryScore}%</div>
          </div>
          <div className="w-100 d-flex mb-2">
            <small className="w-25 me-2 text-nowrap">Skills</small>
            <div className="w-50">
              <div
                className="progress h-100 me-2"
                // role="progressbar"
                // aria-label="Example with label"
                // aria-valuenow="25"
                // aria-valuemin="0"
                // aria-valuemax="100"
              >
                <div
                  class="progress-bar bg-danger"
                  style={{ width: `${data.skillsScore}%` }}
                ></div>
              </div>
            </div>
            <div className="w-25">{data.skillsScore}%</div>
          </div>
          <div className="w-100 d-flex">
            <small className="w-25 me-2 text-nowrap">Education</small>
            <div className="w-50">
              <div
                className="progress h-100 me-2"
                // role="progressbar"
                // aria-label="Example with label"
                // aria-valuenow="25"
                // aria-valuemin="0"
                // aria-valuemax="100"
              >
                <div
                  class="progress-bar bg-success"
                  style={{ width: `${data.educationScore}%` }}
                ></div>
              </div>
            </div>
            <div className="w-25">{data.educationScore}%</div>
          </div>
        </div>
        {/* <div className="col-md-2">
          <Button className="match-score__btn d-flex ms-auto">
            Regenerate
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default MatchScore;
