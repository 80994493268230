import {
  faCheck,
  faCopy,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faMailReply,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "../../../assets/scss/Components/UI/Modal/_socialMediaShareContainer.scss";

import Invitation from "./Invitation";
const SocialMediaShare = ({
  url,
  subject,
  message,
  title,
  hasInvitation,
  messageId,
}) => {
  return (
    <div className="socialMediaShareContainer">
      <FacebookShareButton url={url}>
        <FacebookIcon size={30} round={true} />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        url={url}
        title={subject}
        appId="242548685409116"
      >
        <FacebookMessengerIcon size={30} round={true} />
      </FacebookMessengerShareButton>{" "}
      <WhatsappShareButton url={url} title={subject} separator={`\n`}>
        <WhatsappIcon size={30} round={true} />
      </WhatsappShareButton>
      <TwitterShareButton url={url} title={subject}>
        <TwitterIcon size={30} round={true} />
      </TwitterShareButton>
      <ViberShareButton url={url} title={subject} separator={`\n`}>
        <ViberIcon size={30} round={true} />
      </ViberShareButton>
      <ShareCopyLink url={url} />
      {hasInvitation && <Invitation message={message} messageId={messageId} />}
    </div>
  );
};

function ShareCopyLink(url) {
  const [isCopied, setIsCopied] = useState(false);
  // console.log(url.url);
  const copyToClipboard = () => {
    const linkToCopy = url.url;

    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000); // Revert back to default after 5 seconds
      })
      .catch((error) => console.error("Could not copy link: ", error));
  };

  return (
    <>
      <div className="copyIcon">
        <FontAwesomeIcon
          icon={isCopied ? faCheck : faCopy}
          size="lg"
          onClick={copyToClipboard}
          style={{
            width: "15px",
            margin: "0",
            background: isCopied ? "green" : "grey",
            color: "white",
            fontSize: "15px",
            padding: "7px",
            borderRadius: "100%",
            cursor: "pointer",
            marginTop: "3px",
          }}
        />
      </div>
    </>
  );
}

export default SocialMediaShare;
