import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import axios from "axios";
import { useAuthToken } from "../../../../../../Utilities/Auth/Token";
import { localDevBase } from "../../../../../../services/API/localDevBase";
import { handleAxiosError } from "../../../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
const StatusID = ({ editData, isViewJob, checkJobStatus }) => {
  const token = useAuthToken();
  const devBase = localDevBase();
  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const isActive = watch("isActive");
  const [switchInteracted, setSwitchInteracted] = useState(false);

  useEffect(() => {
    if (editData) {
      setValue("isActive", editData?.isActive);
    }
  }, [editData, setValue]);

  // useEffect(() => {
  //   if (switchInteracted) {
  //     console.log("isActive changed:", isActive);
  //     changeStatus();
  //   }
  // }, [switchInteracted]);

  const changeStatus = async () => {
    console.log(editData);

    if (editData.jobPostStatus === "open") {
      const atsPayload = [
        {
          patchOptions: {
            jobId: editData.jobId,
            updatedAt: editData.updatedAt,
          },
          setOptions: {
            isActive: !isActive,
          },
        },
      ];
      try {
        const atsStatus = await axios.patch(
          `${devBase}/api/ats/manage-job/atsJob`,
          atsPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(atsStatus);

        if (isActive !== true) {
          checkJobStatus("addATSForm");
        } else {
          checkJobStatus("removeATSForm");
        }
      } catch (error) {
        console.log("first");
        if (error.response.status == 400) {
          checkJobStatus("cantDeleteForm");
          setValue("isActive", isActive);
          return;
        }

        handleAxiosError(error);

        console.log(error);
      }
    } else {
      checkJobStatus("notOpen");
      setValue("isActive", isActive);
    }
  };

  return (
    <div className="d-flex w-100 gap-5 mb-4">
      <div className="w-100">
        <h4 className="create-job__title border-bottom pb-3 mb-4">
          JOB DETAILS
        </h4>
        <p>
          <strong>Job ID :</strong> {editData?.jobUUID}
        </p>
      </div>
      <div className="w-100">
        <h4 className="create-job__title border-bottom pb-3 mb-4">
          ATS STATUS
        </h4>
        <Form.Check
          {...register("isActive")}
          type="switch"
          id="form-check-input"
          label={isActive ? "Active" : "Not Active"}
          disabled={isViewJob}
          onChange={() => {
            // console.log("isActive", !isActive);
            setSwitchInteracted(true);
            changeStatus();
          }}
        />
      </div>
    </div>
  );
};

export default StatusID;
