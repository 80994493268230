import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCalendarDay,
  faClock,
  faEllipsisVertical,
  faEye,
  faFile,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import "../../../assets/scss/Components/UI/DragAndDrop/_card.scss";
import CustomOffcanvas from "../Offcanvas/CustomOffcanvas";
import logo from "../../../assets/images/Icon/iconBuilding.png";
import AllInformation from "../../../pages/Admin/ATS/AllInformation/AllInformation";
import moment from "moment";
import axios from "axios";
import { List } from "react-content-loader";
import ModalOnly from "../Modal/ModalOnly";
import {
  atsColumn,
  columnNameMappings,
  columnNameMappingsRev,
} from "../../../Utilities/Reusable/Validation/Validator/Column.ats";
import Config from "../../../Config/config";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import { localDevBase } from "../../../services/API/localDevBase";
import Loader from "../../../layouts/loader/Loader";
const Card = ({ item, draggableId, onDropdownSelect, handleClickOption }) => {
  const devBase = localDevBase();
  const token = useAuthToken();
  const [resumeData, setResumeData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isOCShowing, setisOCShowing] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  // temporary disable name
  const [sourceJobTalent, setSourceJobTalent] = useState(true);

  const clickName = (id) => {
    setisOCShowing(true);
    setSourceJobTalent(true);
  };

  useEffect(() => {
    setisLoading(true);
  }, [item]);

  const handleClose = () => setisOCShowing(false);
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", item.id);
  };

  const viewResume = async () => {
    setModalShow(true);

    try {
      const response = await axios.get(
        `${devBase}/api/ats/candidate-list/resume`,
        {
          params: {
            file_name: item.resume,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResumeData(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownSelect = (selectedTransition, e) => {
    if (typeof onDropdownSelect === "function") {
      onDropdownSelect(item, selectedTransition);
    } else {
      console.error("onDropdownSelect is not a function");
    }
  };

  const getDropdownItems = () => {
    const currentColumn = item.status;
    const draftStatus = item.draftStatus || "";

    let validTransitions = (atsColumn[currentColumn] || []).filter(
      (transition) => transition !== "Failed" && transition !== "Withdraw"
    );

    if (draftStatus !== "") {
      validTransitions = validTransitions.filter(
        (transition) => transition !== draftStatus
      );
    } else {
      validTransitions.shift();
    }

    return validTransitions.map((transition) => (
      <Dropdown.Item
        key={transition}
        onClick={(e) => {
          handleDropdownSelect(columnNameMappingsRev[transition] || transition);
        }}
      >
        {columnNameMappingsRev[transition] || transition}
      </Dropdown.Item>
    ));
  };

  const [submissionTime, setSubmissionTime] = useState("");
  const [lastSubmitDate, setLastSubmitDate] = useState("");
  const [submissionStatusHistory, setSubmissionStatusHistory] = useState([]);

  useEffect(() => {
    const calculateSubmissionTime = () => {
      const history = item.history && item.history.previousStatus;

      if (history && history.length >= 1) {
        const lastSubmitEvent = history
          .filter((event) => event.statusType === "submit")
          .pop();

        const submitEvents = history.filter(
          (event) => event.statusType === "submit"
        );

        const sortedSubmitEvents = submitEvents.sort(
          (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
        );

        setSubmissionStatusHistory(sortedSubmitEvents);

        if (lastSubmitEvent) {
          const lastSubmitTime = moment(lastSubmitEvent.updatedAt);
          const formattedDate = lastSubmitTime.format("MMMM D, YYYY");

          setLastSubmitDate(formattedDate);

          const minutesAgo = moment().diff(lastSubmitTime, "minutes");
          const hoursAgo = moment().diff(lastSubmitTime, "hours");
          const daysAgo = moment().diff(lastSubmitTime, "days");

          if (daysAgo >= 1) {
            setSubmissionTime(
              `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`
            );
          } else if (hoursAgo >= 1) {
            setSubmissionTime(
              `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`
            );
          } else {
            setSubmissionTime(
              `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} ago`
            );
          }
        }
      }
      setisLoading(false);
    };

    const intervalId = setInterval(() => {
      calculateSubmissionTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [item]);

  return (
    <div className="dndCard" draggable onDragStart={handleDragStart}>
      <div className="imgContainer">
        <img
          crossOrigin="anonymous"
          src="sample.img"
          alt="logo"
          onError={(e) => {
            e.target.src = logo;
            e.target.classList.add("errorImg");
          }}
        />
      </div>
      {/* Details */}
      <div className="details">
        {/* Title with Option */}
        <div className="details-name-option">
          <div className="nameOption">
            <h2>{item.firstName + " " + item.lastName}</h2>
          </div>
          <div className="option">
            <FontAwesomeIcon
              icon={faEye}
              className="iconOption"
              onClick={() => clickName(item.id)}
            />
            <FontAwesomeIcon
              icon={faFile}
              className="iconOption"
              onClick={viewResume}
            />
            {item.status !== "Hired" && (
              <>
                <Dropdown onClick={(e) => e.stopPropagation()}>
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="verticalEllipsis"
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>{getDropdownItems()}</Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        </div>

        <div className="cityWorkArrangement">
          <div></div>
        </div>
        {isLoading ? (
          <List />
        ) : (
          <>
            <div className="card-status">
              <p>
                {submissionStatusHistory.length > 0 ? (
                  <p>
                    {submissionStatusHistory.map((statusEvent, index) => (
                      <React.Fragment key={index}>
                        {columnNameMappingsRev[statusEvent.status] ||
                          statusEvent.status}
                        {index < submissionStatusHistory.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  </p>
                ) : (
                  <p>New Applicant</p>
                )}
              </p>
            </div>
            <div className="calendar-time-container">
              <div className="calendar">
                <>
                  <FontAwesomeIcon icon={faCalendarDay} className="icon" />
                  <p>
                    {lastSubmitDate
                      ? lastSubmitDate
                      : moment(item.dateAppliedISO).format("MMMM D, YYYY")}
                  </p>
                </>
              </div>
              {submissionTime && (
                <div className="calendar">
                  <FontAwesomeIcon icon={faClock} className="icon" />
                  <p>{submissionTime}</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <CustomOffcanvas
        show={isOCShowing}
        handleClose={handleClose}
        placement="end"
        title="View Candidate"
      >
        <AllInformation
          sourceJobTalent={sourceJobTalent}
          item={item}
          hasNotes={true}
          typeList="Candidate"
        />
      </CustomOffcanvas>
      <ModalOnly show={modalShow} handleClose={() => setModalShow(false)}>
        <iframe className="view_resume_file" src={resumeData}></iframe>
      </ModalOnly>
    </div>
  );
};

export default Card;
