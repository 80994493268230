import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import CustomButton from "./Button";
import "../../assets/scss/Components/UI/_modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "./Button";
import {
  faCheck,
  faExclamation,
  faQuestion,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const CustomModal = ({
  secondButtonName,
  buttonName,
  onHide,
  show,
  color,
  icon,
  modalType,
  onClick,
  modalMessage,
  hasOption,
  loading,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        className="customModal"
        backdrop="static"
      >
        <Modal.Header closeButton={hasOption && onHide}>
          <ModalTitle className="title">
            <div
              className={`${
                hasOption ? "iconContainerCenter" : "iconContainer"
              } ${
                modalType === "Success"
                  ? "green"
                  : modalType === "Warning"
                  ? "yellow"
                  : "red"
              }`}
            >
              <FontAwesomeIcon
                icon={
                  modalType === "Success"
                    ? faCheck
                    : modalType === "Warning"
                    ? faQuestion
                    : faTimes
                }
                className="icon"
              />
            </div>
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <h1>{modalType}</h1>
          <div className="messageContainer">
            <p>{modalMessage}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modalBtns d-flex">
            {buttonName && (
              <Button
                id="loginModalBtn"
                disabled={loading}
                loading={loading}
                label={buttonName}
                onClick={onClick}
                classNameLabel={`modalButton ${
                  modalType === "Success"
                    ? "green"
                    : modalType === "Warning"
                    ? "yellow"
                    : "red"
                }`}
              />
            )}
            {secondButtonName && (
              <Button
                label={secondButtonName}
                onClick={onHide}
                // classNameLabel={`modalButton ${
                //   modalType === "Success"
                //     ? "green"
                //     : modalType === "Warning"
                //     ? "yellow"
                //     : "red"
                // }`}
              />
            )}
          </div>

          {/* {buttonName && !hasOption ? (
            <Button
              label={buttonName}
              onClick={onClick}
              classNameLabel={`modalButton ${
                modalType === "Success" ? "green" : "Warning" ? "yellow" : "red"
              }`}
            />
          ) : hasOption ? (
            <div className="d-flex w-100 gap-2">
              <Button
                label="Cancel"
                onClick={onClick}
                classNameLabel="cancel"
              />
              <Button
                label={buttonName}
                onClick={onClick}
                classNameLabel={`modalButton ${
                  modalType === "Success"
                    ? "green"
                    : "Warning"
                    ? "yellow"
                    : "red"
                }`}
              />
            </div>
          ) : (
            <>
              <Button
                label={buttonName}
                onClick={onClick}
                classNameLabel={`modalButton ${
                  modalType === "Success"
                    ? "green"
                    : "Warning"
                    ? "yellow"
                    : "red"
                }`}
              />
            </>
          )} */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
