import React from "react";
import "../../../assets/scss/Components/UI/Card/_subscriberCard.scss";
import logo from "../../../assets/images/Icon/iconBuilding.png";
const SubscriberCard = ({ subscriberData, additionalComponents }) => {
  return (
    <div className="subscriberCard">
      <div className="subscriberDetails">
        <div>
          <div className="subscriberImage">
            <img
              src={"subscriberData.image"}
              alt={subscriberData?.companyName}
              onError={(e) => {
                e.target.src = logo;
                e.target.classList.add("errorImg");
              }}
            />
          </div>
          <h3>{subscriberData?.companyName}</h3>
          <p>{subscriberData?.hiringManager?.contactNumber}</p>
          <p>{subscriberData?.hiringManager?.email}</p>
          <p>{subscriberData?.hiringManager?.name}</p>
        </div>
        <div className="vl"></div>
      </div>

      <div className="additionalComponents">{additionalComponents}</div>
    </div>
  );
};

export default SubscriberCard;
