import { useState } from "react";
import Config from "../Config/config";
import axios from "axios";
import { useSelector } from "react-redux";

export const JobAI = () => {
  const userInfo = useSelector(
    (state) => state.reducer.user?.currentUser.userId
  );
  const [recommendedJobsData, setRecommendedJobsData] = useState(null);
  const getRecommendedJobs = async (contentPayload) => {
    const payload = {
      createdAt: "2024-04-25T05:36:43.726Z",
      content: {
        contentPayload,
        matchCount: 3,
      },
    };

    console.log(payload);

    try {
      const response = await axios.post(
        `https://nxtgenai.azurewebsites.net/api/Matching/job`,
        payload,
        { withCredentials: true }
      );
      console.log(response.data.content);
      setRecommendedJobsData(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return { getRecommendedJobs, recommendedJobsData };
};
