import React, { useEffect, useState } from "react";
import FormModal from "../../UI/Modal/FormModal";
import CreatableSelect from "react-select/creatable";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "reactstrap";
import axios from "axios";
import { useParams } from "react-router";
import Config from "../../../Config/config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";
import "../../../assets/scss/Page/Client/Job/_invitationModal.scss";
import Loader from "../../../layouts/loader/Loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
const InvitationForm = ({ show, handleClose, message, messageId }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const token = useAuthToken();
  const { control, handleSubmit, setValue } = useForm();
  const [inputValue, setInputValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [responseMsg, setResponseMsg] = useState("");
  const [isSent, setIsSent] = useState(false);

  const components = {
    DropdownIndicator: null,
  };

  useEffect(() => {
    if (!show) {
      setEmailError("");
      setMessageError("");
      setResponseMsg("");
      setValue("talentsEmail", []);
      setSelectedEmails([]);
    }
  }, [show]);

  // Define a Zod schema for email validation
  const emailSchema = z.string().email("Invalid email address");
  const messageSchema = z.string().min(1, "Message cannot be empty");

  const onSubmit = async (data) => {
    setEmailError("");
    setMessageError("");

    const emails = data.talentsEmail;
    const messages = data.message;
    // console.log("message ", messages);
    // console.log("data ", data);
    if (emails.length === 0) {
      setEmailError("Please enter at least one email address");
    }

    if (!messages === 0) {
      setMessageError("Message cannot be empty");
    }

    try {
      messageSchema.parse(message);
    } catch (error) {
      console.log(error);
      setMessageError("Message cannot be empty");

      return;
    }

    const transformedData = emails.map((item) => item.value);
    // console.log(transformedData);
    const l = { talentsEmail: transformedData, jobId: id };
    // console.log(l);

    try {
      setResponseMsg("");
      setIsLoading(true);
      // const htmlMessage = `<html>${messages}</html>`;
      // console.log(htmlMessage);
      const response = await axios.post(
        `${Config.nxtApi}/api/client/invite

`,
        {
          talentsEmail: transformedData,
          jobId: messageId,
          message: messages,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setIsSent(true);
      setResponseMsg("Email Sent");
      setValue("talentsEmail", []);
      setSelectedEmails([]);
      // setValue("message", "");

      // console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("API Error:", error);
    }
  };

  const handleKeyDown = async (event, field) => {
    // Check if the Enter key (key code 13) is pressed
    if (event.keyCode === 13 && event.target.value) {
      // Prevent the default behavior (e.g., form submission)
      event.preventDefault();

      const emailValue = event.target.value;

      // Check if the email is already selected
      if (selectedEmails.some((email) => email.value === emailValue)) {
        setEmailError("Email already selected");
        return;
      }

      try {
        // Validate the email using Zod schema
        emailSchema.parse(emailValue);

        // Create a new option object
        const newOption = {
          value: emailValue,
          label: emailValue,
        };

        // Update the field value with the new option
        field.onChange([...field.value, newOption]);

        // Clear the input field by updating the state
        setInputValue("");

        // Clear any previous email error message
        setEmailError("");

        // Update the selected emails state
        setSelectedEmails([...selectedEmails, newOption]);
      } catch (error) {
        // Handle the validation error (invalid email)
        setEmailError("Invalid email address");
      }
    }
  };

  return (
    <>
      <FormModal
        show={show}
        handleClose={() => {
          handleClose();
        }}
        modalTitle="Email Invitation"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="talentsEmail"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <>
                <CreatableSelect
                  placeholder="Enter email address"
                  components={components}
                  isMulti
                  menuIsOpen={false}
                  onKeyDown={(e) => handleKeyDown(e, field)}
                  {...field}
                  inputValue={inputValue}
                  onInputChange={(value) => {
                    setInputValue(value);
                    setResponseMsg("");
                    setEmailError(""); // Clear email error when typing
                  }} // Update input value
                  onChange={(selectedOptions) => {
                    field.onChange(selectedOptions);
                  }}
                  onCreateOption={(inputValue) => {
                    const newOption = { value: inputValue, label: inputValue };

                    if (
                      selectedEmails.some((email) => email.value === inputValue)
                    ) {
                      setEmailError("Email already selected");
                      return;
                    }

                    field.onChange([...field.value, newOption]);

                    setInputValue("");

                    setEmailError("");

                    setSelectedEmails([...selectedEmails, newOption]);
                  }}
                />
                {emailError && (
                  <p style={{ color: "red", margin: "10px 0" }}>{emailError}</p>
                )}{" "}
              </>
            )}
          />
          <Controller
            name="message"
            control={control}
            defaultValue={message}
            render={({ field }) => (
              <>
                <ReactQuill
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    setMessageError("");
                    setResponseMsg("");
                  }}
                  className="invitationMessage"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],

                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  placeholder="Enter job overview..."
                />
                {/* <TextArea
                  defaultValue={message}
                  placeholder="Message..."
                  className="invitationMessage"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setMessageError("");
                    setResponseMsg("");
                    // Clear message error when typing
                  }}
                /> */}
                {messageError && (
                  <p style={{ color: "red", margin: "10px 0" }}>
                    {messageError}
                  </p>
                )}
              </>
            )}
          />
          {responseMsg && (
            <p
              style={{
                color: isSent ? "green" : "red",
                margin: "10px 0",
              }}
            >
              {responseMsg}
            </p>
          )}
          <div className="btnContainer">
            <Button onClick={handleClose} className="cancelBtn">
              Cancel
            </Button>
            <Button type="submit" className="yellowBtn">
              {isLoading ? <Loader /> : "Submit"}
            </Button>
          </div>
        </form>
      </FormModal>
    </>
  );
};

export default InvitationForm;
