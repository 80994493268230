import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import { Button } from "react-bootstrap";
import "../../../../assets/scss/Page/Admin/ATS/UserManagement/_atsUserManagement.scss";
import UserManagementTable from "./UserManagementTable/UserManagementTable";
import UserManagementModal from "./UserManagementTable/UserManagementModal";
import CustomModal from "../../../../components/UI/Modal";

const UserManagement = () => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [delBtnClick, setDelBtnClick] = useState(false);
  const [addBtnClick, setAddBtnClick] = useState(false);
  const [modalMode, setModalMode] = useState("");

  const handleAddUserModal = () => {
    setShow(true, { state: { addUser: "adduser" } });
  };

  const clickTrue = (modalMode) => {
    console.log("Im clicking");
    if (modalMode === "deleteOption") {
      setDelBtnClick(true);
    } else if (modalMode === "addOption") {
      setAddBtnClick(true);
    }
  };

  const handleAddModal = (isTrue, modalMode) => {
    console.log(isTrue, modalMode);
    setShowModal(isTrue);
    setModalMode(modalMode);
  };

  const [load, setLoad] = useState(false);
  const handleLoadTable = () => {
    setLoad(true);
    setShowModal(false);
    setAddBtnClick(false);
    // setLoad(false);
  };

  const onAddUserSuccess = () => {
    setModalMode("successAdd");
    setLoad(!load);
  };

  const handleErrorAddModal = (errorType) => {
    if (errorType === "alreadyExist") {
      setModalMode("alreadyExist");
      // setShowModal(true);
    } else if (errorType === "") {
    }
  };

  const handleShowAddUserModal = () => {
    setModalMode("addUserOption");
    setShowModal(true);
  };

  const [addConfirmation, setAddConfirmation] = useState(false);
  const confirmAddUser = () => {
    setAddConfirmation(!addConfirmation);
  };

  const [clickDeleteUser, setClickDeleteUser] = useState(false);
  const delUser = () => {
    setClickDeleteUser(!clickDeleteUser);
  };

  const onEditUserSuccess = () => {
    setModalMode("successEdit");
    setShowModal(true);
    setAddConfirmation(false);
  };

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer">
          <WhiteContainer>
            <div className="ats__user-management">
              <div className="d-flex p-4">
                <Button
                  className="btn border-0 ats__btn-adduser ms-auto"
                  onClick={handleAddUserModal}
                >
                  Add user
                </Button>
              </div>
              <UserManagementTable
                setShowModal={setShowModal}
                setModalMode={setModalMode}
                deleteUser={delBtnClick}
                load={load}
                clickDeleteUser={clickDeleteUser}
              />
            </div>
          </WhiteContainer>
        </div>
      </div>
      <UserManagementModal
        show={show}
        close={() => setShow(false)}
        setAddModal={handleAddModal}
        addBtnClick={addBtnClick}
        setModalMode={setModalMode}
        onEditUserSuccess={onEditUserSuccess}
        onAddUserSuccess={onAddUserSuccess}
        showAddModal={handleShowAddUserModal}
        addConfirmation={addConfirmation}
        onErrorAddModal={handleErrorAddModal}
      />
      <CustomModal
        hasOption={true}
        show={showModal}
        buttonName={
          modalMode === "successDelete" ||
          modalMode === "successAdd" ||
          modalMode === "alreadyExist"
            ? "OK"
            : "YES"
        }
        onHide={() => setShowModal(false)}
        secondButtonName={
          modalMode === "successDelete" ||
          modalMode === "successAdd" ||
          modalMode === "alreadyExist" ||
          modalMode === "successEdit"
            ? null
            : "NO"
        }
        modalMessage={
          modalMode === "addUserOption"
            ? "Confirm adding this user?"
            : modalMode === "successAdd"
            ? "User successfully invited!"
            : modalMode === "deleteUserOption"
            ? "Are you sure you want to remove this user?"
            : modalMode === "successDelete"
            ? "User Succesfully Removed"
            : modalMode === "alreadyExist"
            ? "Email already registered."
            : modalMode === "successEdit"
            ? "User role change successful"
            : ""
        }
        modalType={
          modalMode === "successAdd" ||
          modalMode === "successDelete" ||
          modalMode === "successEdit"
            ? "Success"
            : modalMode === "addUserOption" || modalMode === "deleteUserOption"
            ? "Warning"
            : "Oops!"
        }
        onClick={() => {
          modalMode === "addUserOption"
            ? confirmAddUser()
            : modalMode === "deleteUserOption"
            ? delUser()
            : modalMode === "successAdd" ||
              modalMode === "successDelete" ||
              modalMode === "alreadyExist" ||
              modalMode === "successEdit"
            ? setShowModal(false)
            : setShowModal(false);
        }}
      />
    </div>
  );
};

export default UserManagement;
