import {
  faPaperPlane,
  faTrash,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useChatAssistant } from "../../../hook/useChatAssistant";
import Config from "../../../Config/config";
import { useLocation } from "react-router-dom";
import { clearAI } from "../../../services/Actions/aiAction";
import { useDispatch } from "react-redux";
import logo from "../../../assets/images/Icon/userIcon.png";
import { List } from "react-content-loader";
import Button from "../../UI/Button";

const ChatAssistant = () => {
  const chatBoxRef = useRef(null);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const {
    initiateChat,
    initiateChatData,
    sendMessageChatAssistant,
    chatMessageData,
    loadChatMessage,
    getChatHistory,
    chatHistoryErr,
    error15s,
  } = useChatAssistant();
  const clientPic = useSelector(
    (state) => state.reducer.companyProfile.currentCompanyProfile?.profilePic
  );
  const clientName = useSelector(
    (state) => state.reducer.companyProfile.currentCompanyProfile?.firstName
  );
  const userInfo = useSelector((state) => state.reducer.user.currentUser);
  const aiInfo = useSelector((state) => state.reducer.aimlInfo.aiml);

  const [chatText, setChatText] = useState("");
  const [messages, setMessages] = useState([]); // Define messages state here
  const [errorMsg, setErrorMsg] = useState(false);
  const [cookiesEnabled, setCookiesEnabled] = useState(true);

  const [isProfileAvailable, setIsProfileAvailable] = useState(null);

  const handleDeleteInputText = () => {
    setChatText("");
  };

  const sendMessage = (chatText) => {
    if (!chatText.trim()) return;

    sendMessageChatAssistant(chatText);

    const newMessage = {
      content: {
        text: chatText.trim(),
      },

      dialogueType: "register",
      isRichText: false,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    setChatText("");
  };
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (aiInfo?.error === true || !aiInfo?.sessionId) {
      initiateChat();
      return;
    }

    if (location.pathname === "/admin/ats/chatbot" && aiInfo?.sessionId) {
      getChatHistory();
    }

    return () => {
      dispatch(clearAI());
    };
  }, [userInfo.userId, aiInfo?.error]);

  useEffect(() => {
    if (chatMessageData && chatMessageData.content) {
      const resMessage = {
        content: {
          text: chatMessageData.content.text,
        },
        dialogueType: chatMessageData.dialogueType,
        isRichText: chatMessageData.content.isRichText,
      };

      setMessages((prevMessages) => [...prevMessages, resMessage]);
    } else if (chatMessageData && chatMessageData.dialogue) {
      const formattedMessages = chatMessageData.dialogue.map((message) => ({
        content: {
          text: message.content.text,
        },
        dialogueType: message.dialogueType,
        isRichText: message.content.isRichText,
      }));

      setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);
    }
  }, [chatMessageData]);

  useEffect(() => {
    setIsProfileAvailable(
      `${Config.nxtApi}/api/talent/profile-pic?fileName=${encodeURIComponent(
        clientPic
      )}`
    );
  }, [clientPic]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const hasChatHistoryError = () => {
      if (chatHistoryErr === "noCookies") {
        setCookiesEnabled(false);
      }

      if (chatHistoryErr === "500") {
        setCookiesEnabled(false);
      }
    };

    hasChatHistoryError();
  }, [chatHistoryErr]);

  return (
    <>
      {initiateChatData === "load" ? (
        <List />
      ) : initiateChatData === "error" ? (
        <div className="errorSessionId">
          <img
            src={require("../../../assets/images/Logo/Brand/nexie.png")}
          ></img>
          <h1>Oops! We couldn't connect to the server.</h1>
          <p>Please refresh the page or click the button below to try again.</p>
          <button onClick={() => initiateChat()}>Retry</button>
        </div>
      ) : (
        <div className="chat-assistant">
          <div
            className="chat-assistant_box position-relative"
            ref={chatBoxRef}
          >
            {messages.length > 0 ? (
              messages.map((message, index) => (
                <>
                  <div
                    key={index}
                    className={`${
                      message.dialogueType === "register"
                        ? "chat-assistant__talent-bestmatch d-flex justify-content-end mb-3"
                        : "chat-assistant__ai d-flex mb-3"
                    }`}
                  >
                    {message.dialogueType !== "register" && (
                      <div className="chat-assistant__ai-logo"></div>
                    )}
                    <div
                      className={`${
                        message.dialogueType === "register"
                          ? "chat-assistant__talent-text"
                          : "chat-assistant__ai-text"
                      }`}
                    >
                      <div
                        className={`${
                          message.dialogueType === "register"
                            ? "triangle-right"
                            : "triangle-left"
                        }`}
                      ></div>
                      {message.isRichText ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: message.content.text,
                          }}
                        ></p>
                      ) : (
                        message.content.text
                      )}
                    </div>
                    {message.dialogueType === "register" && (
                      <div className="chat-assistant__talent-logo">
                        {encodeURIComponent(clientPic) === "undefined" ? (
                          <>
                            <FontAwesomeIcon
                              className="chat-assistant__talent-logo-img"
                              icon={faUserCircle}
                            />
                          </>
                        ) : (
                          <img
                            className="chat-assistant__talent-logo-img"
                            crossOrigin="anonymous"
                            src={isProfileAvailable}
                            onError={(e) => {
                              if (clientPic === undefined) {
                                e.target.classList.remove("errorImg");
                              } else {
                                e.target.src = logo;
                                e.target.classList.add("errorImg");
                              }
                            }}
                          ></img>
                        )}
                      </div>
                    )}
                  </div>
                </>
              ))
            ) : (
              <>
                {" "}
                <div className="position-absolute top-50 start-50 translate-middle text-center w-100 d-flex flex-column justify-content-center align-items-center">
                  <h3>Hi, {clientName}!</h3>
                  <h4>I'm Nexie, how can I help you today?</h4>
                  <div className="chat-assistant__button-container w-100 justify-content-center">
                    <button
                      className="button-container__buttons"
                      onClick={(e) => {
                        sendMessage(
                          "I am an NXT Employer. Can you give me 5 instructions on creating a Job Description within NXT?"
                        );
                      }}
                    >
                      I want to create a Job Description
                    </button>
                    <button
                      className="button-container__buttons"
                      onClick={(e) => {
                        sendMessage(
                          "I am an NXT Employer and I need a list of instructions on how to find a Jobseeker match for my job post within NXT?"
                        );
                      }}
                    >
                      I want to look for a Jobseeker
                    </button>
                  </div>
                </div>
              </>
            )}

            {loadChatMessage && (
              <div className="chat-assistant__loader position-absolute"></div>
            )}

            {errorMsg && (
              <div className="chat-assistant__error position-absolute mx-5">
                There was an error generating response
              </div>
            )}
            {/* 
              add inactive to remove overlay
              sample "overlay inactive"
            */}
            <div className={`overlay ${cookiesEnabled ? "inactive" : ""}`}>
              <div className="overlay-txt bg-white p-2 p-sm-2 p-md-4">
                <img
                  className="d-flex mx-auto mb-3"
                  src={require("../../../assets/images/cookies.png")}
                />
                <p>
                  To ensure you get the most out of our AI Chatbot, please
                  activate third-party cookies in your browser settings. This
                  helps us personalize your experience and provide you with
                  tailored content.
                </p>
                <p>
                  Here's a quick guide on how to enable third-party cookies:
                </p>
                <p>Chrome:</p>
                <ul>
                  <li>Click on the three dots in the top right corner.</li>
                  <li>
                    Go to "Settings" &gt; "Privacy and security" &gt; "Cookies
                    and other site data".
                  </li>
                  <li>Make sure "Block third-party cookies" is toggled off.</li>
                </ul>
                <p>
                  Once you've made these adjustments, reload the page and enjoy
                  our full range of features!
                </p>
                <p>
                  If you need further assistance, don't hesitate to reach out
                  to&nbsp;
                  <a href="mailto:support@nxt.com.ph">support@nxt.com.ph</a>.
                </p>
              </div>
            </div>
          </div>
          <Form
            className="mb-3 position-relative"
            onSubmit={(e) => {
              e.preventDefault();
              sendMessage(chatText);
            }}
          >
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="How can we help?"
              value={chatText}
              disabled={!cookiesEnabled}
              onChange={(e) => setChatText(e.target.value)}
            />
            <button className="trash" onClick={handleDeleteInputText}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button type="submit" disabled={!cookiesEnabled}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </Form>
        </div>
      )}
    </>
  );
};

export default ChatAssistant;
