import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
// import Footer from "../../layouts/Footer";
import Banner from "../../components/Banner";
import ApplicantList from "../../components/LayoutClient/ApplicantList/ApplicantList";
import FilterList from "../../components/LayoutClient/ApplicantList/FilterList";
import BasicInformation from "../../components/LayoutClient/ApplicantList/ViewResume/BasicInformation";
import KeySkills from "../../components/LayoutClient/ApplicantList/ViewResume/KeySkills";
import WorkHistory from "../../components/LayoutClient/ApplicantList/ViewResume/WorkHistory";
import DocumentsCert from "../../components/LayoutClient/ApplicantList/ViewResume/DocumentsCert";
import DocumentsCV from "../../components/LayoutClient/ApplicantList/ViewResume/DocumentsCV";
import SidebarViewResume from "../../layouts/Client/SidebarViewResume";
// import FooterClient from "../../layouts/FooterClient";
import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import { useLocation } from "react-router-dom";
import {
  fetchApplicant,
  fetchApplicantData,
} from "../../components/LayoutClient/ApplicantList/ViewResume/fetchData";
import { useAuthToken } from "../../Utilities/Auth/Token";
import { List } from "react-content-loader";
import Footer from "../LandingPage/Components/Footer";

const ViewResume = () => {
  TabTitle("NXT | Talent List");

  const location = useLocation();
  const jobId = location.state?.jobId;
  const talentId = location.state?.talentId;

  const token = useAuthToken();
  const [applicantData, setApplicantData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchApplicant(talentId, token);
        setApplicantData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [talentId, token]);

  const workHistoryData = (applicantData && applicantData[0]?.data) || [];
  const certificateData = (applicantData && applicantData[1]?.data) || [];
  const applicantInfo = (applicantData && applicantData[2]?.data) || {};

  return (
    <div className="background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="jobposting-banner">
        <Banner />
      </div>

      <Container className="jobposting-body">
        <div>
          <div className="sidebar-container">
            <SidebarViewResume data={applicantInfo} />
          </div>
        </div>

        <div className="company-profile-container">
          {isLoading ? (
            <List />
          ) : (
            <>
              <BasicInformation data={applicantInfo} />
              <KeySkills data={applicantInfo} />
              <WorkHistory data={workHistoryData} />
              <DocumentsCV data={applicantInfo} />
              <DocumentsCert data={certificateData[0]} />
            </>
          )}
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default ViewResume;
