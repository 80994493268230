import React, { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Container,
} from "reactstrap";
import "../assets/scss/Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import Config from "../Config/config";
import { useDispatch } from "react-redux";
import { useAuthToken } from "../Utilities/Auth/Token";
import CheckIsSubscribe from "../Utilities/Reusable/Validation/Validator/CheckIsSubscribe.ats";

function Header(args) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("");
  const dispatch = useDispatch();
  const toggleOffcanvas = () => setIsOpen(!isOpen);
  const token = useAuthToken();

  const handleCompanyProfile = () => {
    navigate("/client/company-profile");
  };

  const handleTalentList = () => {
    navigate("/client/talent-list");
    setActiveItem("client/talentlist");
  };

  const feature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withATS
  );

  const handleAddpostjobClick = async () => {
    if (feature) {
      navigate("/admin/ats/managing-job");
    } else {
      navigate("/client/job-posting");
    }

    // navigate("/client/job-posting");
  };

  const handleManageJob = () => {
    navigate("/client/manage-job");
  };

  const handleClickLogo = () => {
    navigate("/");
  };

  const isActiveItem = (routeName) => {
    return location.pathname === `/${routeName}`;
  };

  const [companyList, setCompanyList] = useState([]);

  const data = {
    code: 0,
    result: [
      {
        ownerId: "564efb49-cc55-4859-a446-54d7b3db488d",
        companyName: "Sad Gorl Era",
        subscriptionId: "19d91462-d518-4af4-b0d0-239e2e27be77",
      },
      {
        ownerId: "564efb49-cc55-4859-a446-54d7b3db488d",
        companyName: "Acquiro Inc",
        subscriptionId: "19d91462-d518-4af4-b0d0-239e2e27be77",
      },
      {
        ownerId: "564efb49-cc55-4859-a446-54d7b3db488d",
        companyName: "Asticom",
        subscriptionId: "19d91462-d518-4af4-b0d0-239e2e27be77",
      },
    ],
    message: "ROLES FETCHED",
  };

  const companyDropdown = async () => {
    console.log(data);

    // const response = await axios.get(``,  {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // })
    // setCompanyList(response.data.result);
    setCompanyList(data.result);
    console.log(companyList);
  };

  const companyName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.companyName
  );
  const [selectedCompany, setSelectedCompany] = useState(companyName);
  const handleChooseCompany = (value) => {
    console.log(value);
    setSelectedCompany(value);
  };
  useEffect(() => {
    companyDropdown();
  }, [companyName]);

  return (
    <div className="header-bg">
      <Container style={{ padding: "0" }}>
        <Navbar
          expand="lg"
          bg="light"
          style={{ padding: 0, background: "#ffffff" }}
        >
          <NavbarBrand>
            <img
              alt="nxt-logo"
              src={require("../assets/images/nxt-logo.png")}
              className="nxt-logo"
              onClick={handleClickLogo}
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggleOffcanvas} />

          <Collapse isOpen={isOpen} navbar>
            <Nav className="nav-menu" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="dropdown-menu">
                  Profile
                </DropdownToggle>
                <DropdownMenu end id="dropdown-option">
                  <DropdownItem onClick={handleCompanyProfile}>
                    Company Profile
                  </DropdownItem>
                  <DropdownItem onClick={handleManageJob}>
                    Manage Job
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink
                  onClick={handleTalentList}
                  className="talent-list-btn"
                  active={isActiveItem("client/talentlist")}
                >
                  Applicant Pool
                </NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="talent-list-btn">
                  Help
                </DropdownToggle>
                <DropdownMenu end id="dropdown-option">
                  <DropdownItem onClick={() => navigate("/faq")}>
                    FAQs
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/privacy-policy")}>
                    Privacy Policy
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => navigate("/terms-and-condition")}
                  >
                    Terms and Conditions
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/data-deletion")}>
                    Data Deletion
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/cookie-policy")}>
                    Cookie Policy
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Button
              onClick={handleAddpostjobClick}
              className="post-a-job-btn me-2"
              style={{ background: "#efbb20", border: "none" }}
            >
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              Post a Job
            </Button>
            {/* <DropdownButton
              variant="company__list-btn"
              id="dropdown-item-button"
              title={selectedCompany}
            >
              {companyList.map((val, key) => {
                return (
                  <>
                    <Dropdown.Item
                      as="button"
                      style={{
                        color:
                          selectedCompany === val.companyName
                            ? "blue"
                            : "#000000",
                      }}
                      onClick={() => handleChooseCompany(val.companyName)}
                    >
                      {val.companyName}
                    </Dropdown.Item>
                  </>
                );
              })}
            </DropdownButton> */}
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

export default Header;
