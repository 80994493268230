import React from "react";
import "../ABSI/index.scss";
// import "../ABSI/main.css";
const ABSI = () => {
  return (
    <div className="content-absi">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 information">
            <div className="container-fluid">
              <div className="row pt-5 ms-3">
                <div className="col-md-12">
                  <div className="logo">
                    <div className=" subs">
                      <img src={require("./assets/absi.png")} />
                    </div>
                    <div className="agc ">
                      <img src={require("./assets/agc.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row mt-5">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <p className="text-center landingTitle">Redirect Notice</p>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <p class="text-center">
                    You are now redirected to NXT, a partner of ABSI for job
                    openings and career opportunities.
                  </p>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 text-center">
                  <form action="https://www.nxt.com.ph/jobs/">
                    <button className="nxt_primary mt-3">Continue</button>
                  </form>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
            <div className="container-fluid nxtdetails">
              <div className="nxtnote">
                <div className="text-center ">
                  <img className="nxtLogo " src={require("./assets/nxt.png")} />
                </div>
                <div className="text-center">
                  NXT is a partner company of ABSI
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 rightdetails">
            <div className="sideContent">
              <div className="title">
                Discover our latest job openings exclusively on NXT.
              </div>
              <div className="details">
                Apply now and take the next step in your career.
              </div>
            </div>
            <div className="imgmobile mt-5">
              <div className="imgDetails">
                <div className="title">
                  Discover our latest job openings exclusively on NXT.
                </div>
                <div className="details">
                  Apply now and take the next step in your career.
                </div>
              </div>
              <img src={require("./assets/absibg_m.png")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ABSI;
