import Config from "../Config/config";
import axios from "axios";
import { useAuthToken } from "../Utilities/Auth/Token";
import { updateProfile } from "../services/Actions/profileSlice";
import { useDispatch } from "react-redux";
import { CVParsing } from "./CVParsing";
import { useEffect, useState } from "react";

export const useTalent = () => {
  const token = useAuthToken();
  const dispatch = useDispatch();
  const { parseCV } = CVParsing();
  const [s3Url, setS3Url] = useState("");
  const [uploadCVLoad, setUploadCVLoad] = useState(false);
  const uploadCV = async (cv, cvParsing) => {
    setUploadCVLoad(true);
    try {
      const formData = new FormData();
      formData.append("file", cv);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      if (cvParsing === "1") {
        config.params = {
          cvParsing: cvParsing,
        };
      }

      const response = await axios.post(
        `${Config.nxtApi}/api/talent/uploadresume`,
        formData,
        config
      );

      console.log(response);
      dispatch(updateProfile({ resume: response.data.fileName }));

      if (response.data.s3Url) {
        // parseCV(response.data.s3Url);
        setS3Url(response.data.s3Url);
      }

      setUploadCVLoad(false);
    } catch (error) {
      setUploadCVLoad(false);
      console.log("uploadCV : ", error);
    }
  };

  const [viewJobData, setViewJobData] = useState([]);
  const [viewJobDetailsLoad, setViewJobDetailsLoad] = useState(false);

  const viewJobDetails = async (id, job_id) => {
    setViewJobDetailsLoad(true);
    console.log(job_id);
    const payload = {
      job_id: JSON.stringify({ jobId: id, job_id: job_id }),
    };

    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/viewjob`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("Job Details: ", response);
      setViewJobData(response.data);
      setViewJobDetailsLoad(false);
    } catch (error) {
      setViewJobDetailsLoad(false);
      console.log("viewJob ", error);
    }
  };

  return {
    uploadCV,
    viewJobDetails,
    viewJobData,
    s3Url,
    uploadCVLoad,
    viewJobDetailsLoad,
  };
};
