import { useNavigate } from "react-router";
import CustomModal from "../../../components/UI/Modal";
import { useDispatch } from "react-redux";
import { LogoutAndClearProfile } from "../../../services/Actions/logoutAction";

export const CustomModalError = ({ isOpen, errorNumber, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(LogoutAndClearProfile());
    localStorage.clear();
    sessionStorage.clear();
    console.log("Logout ...");
    navigate("/");
    onClose();
  };

  return (
    <div>
      <CustomModal
        show={isOpen}
        onClick={() => handleLogout()}
        buttonName="OK"
        modalType="Error"
        modalMessage={
          errorNumber === "401" || errorNumber === 401
            ? "You have been automatically logged out"
            : errorNumber === "403" || errorNumber === 403
            ? "You'll be logged out due to inactivity"
            : errorNumber === "500" || errorNumber === 500
            ? "Something went wrong"
            : errorNumber === "404" || errorNumber === 404
            ? "API Not found"
            : ""
        }
      />
    </div>
  );
};
