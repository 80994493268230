import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import CustomInput from "../../../../../../components/Form/CustomInput";
import moment from "moment";
import { useSelector } from "react-redux";

const PostingDate = ({ editData, isViewJob }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (editData) {
      const convertStartDate = moment(editData.postingDate?.startDate).format(
        "YYYY-MM-DD"
      );

      const convertEndDate = moment(editData.postingDate?.endDate).format(
        "YYYY-MM-DD"
      );

      setValue("startDate", convertStartDate);
      setValue("endDate", convertEndDate);
    }
  }, [editData, setValue]);

  return (
    <div>
      <h4 className="create-job__title border-bottom pb-3 mb-4 mt-5">
        POSTING DATE
      </h4>
      <Row>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomInput
            errors={errors}
            label="Start Date"
            type="date"
            {...register("startDate")}
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomInput
            errors={errors}
            label="End Date"
            type="date"
            {...register("endDate")}
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
      </Row>
    </div>
  );
};

export default PostingDate;
