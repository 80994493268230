import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "../../../../../assets/scss/Talent/Layout/JobList/JobListContent.scss";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Parser } from "html-to-react";
import SuccessModal from "../../../../../components/SuccessModal";
import WarningModal from "../../../../../components/WarningModal";
import Loader from "../../../../../layouts/loader/Loader";
import SuccessIcon from "../../../../../assets/images/SuccessIcon.png";
import WarningIcon from "../../../../../assets/images/WarningIcon.png";
import QuestionIcon from "../../../../../assets/images/QuestionIcon.png";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Config from "../../../../../Config/config";
import CustomModal from "../../../../../components/UI/Modal";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ShareBtn from "../../../../../components/UI/SharePop/ShareBtn";
import { renderToString } from "react-dom/server";
import he from "he";
import { InlineShareButtons } from "sharethis-reactjs";
import JobTitle from "../../../../../Utilities/Analytics/JobTitle";
import { Helmet } from "react-helmet-async";
import MetaData from "../../../../../Utilities/Analytics/JobTitle";
import { async } from "q";
import SocialMediaShare from "../../../../../components/UI/SharePop/SocialMediaShare";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import { useSelector } from "react-redux";
const JobListContent = () => {
  const parse = require("html-react-parser");
  const navigate = useNavigate();
  let { id } = useParams();
  console.log(id);

  const getUserToken = useAuthToken();
  // console.log(getUserToken);
  const getJobPayload = JSON.parse(localStorage.getItem("jobPayload"));
  const apiKey = process.env.REACT_APP_TALENT_API;
  const [jobId, setJobId] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobDetails, setJobDetails] = useState([]);
  const [benefit, setbenefit] = useState([]);
  const [dialect, setdialect] = useState([]);
  const [skill, setskill] = useState([]);
  const [successModalOpen, setsuccessModalOpen] = useState(false);
  const [warningModalOpen, setwarningModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [warningDetails, setWarningDetails] = useState(false);
  const [warningResume, setWarningResume] = useState(false);
  const [isApplied, setIsApplied] = useState(false); // New state variable

  //
  const [modalType, setModalType] = useState("");
  const [icon, setIcon] = useState("");
  const [color, setColor] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [customModal, setCustomModal] = useState(false);
  const [urlJobId, setUrlJobId] = useState("");
  const [shareSuccess, setShareSuccess] = useState(false);
  const isClient = localStorage.getItem("clientToken");
  // console.log(jobId);

  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  const isAuth = useSelector((state) => state.reducer.user.isAuthenticated);

  const isTalentLogIn = () => {
    if (userType === "talent" || (userType === "applicant" && isAuth)) {
      navigate(`/talent/job-list-details/${id}`);

      const obj = {
        id: getJobPayload.id,
        job_id: id,
        // createdAt: createdAt,
      };

      console.log(obj);
      localStorage.setItem("jobPayload", JSON.stringify(obj));
    }
  };

  const isClientLogin = () => {
    if (userType === "client" && isAuth) {
      navigate(`/jd/${id}`);

      const obj = {
        job_id: id,
        // createdAt: createdAt,
      };

      localStorage.setItem("jobPayload", JSON.stringify(obj));
    }
  };

  const setId = localStorage.setItem("pub_id", id);

  const getCompanyLogo = async () => {
    const response = await axios.get(
      `${Config.nxtApi}/api/jobs/job/company/logo`
    );
  };

  useEffect(() => {
    isTalentLogIn();
    isClientLogin();
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${getUserToken}`,
        };

        const response = await axios.post(
          `${Config.nxtApi}/api/jobs/job/details`,
          { job_id: id }
          // {
          //   headers,
          // }
        );
        setskill(response.data.skillSet);
        setdialect(response.data.dialect);
        setbenefit(response.data.benefits);
        setJobDescription(response.data.jobDescription);
        setJobDetails(response.data);
        // setUrlJobId(response.data.id)
        setJobId(response.data.id);
        // setJobId("")
        // const isAppliedResponse = await axios.get(
        //   `${apiKey}/jobs/${id}/is-applied`,
        //   { headers }
        // );
        // setIsApplied(isAppliedResponse.data.isApplied);
      } catch (err) {
        console.log(err);
        navigate("/job-not-found");
      }
    };

    fetchData();
  }, [apiKey, id, getUserToken]);

  const clickApplyJob = async () => {
    if (getUserToken === undefined || getUserToken === null) {
      setModalType("Warning");
      setColor("red");
      setIcon(faXmark);
      setButtonName("Go to Login");
      setModalMessage("You must first Login to apply this job");
      openCustomModal();
    } else {
      setIsLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${getUserToken}`,
        };

        const payload = {
          jobId: getJobPayload.id,
        };

        const response = await axios.post(`${apiKey}/applyjob`, payload, {
          headers,
        });
        toggleSuccessModal();
        setIsLoading(false);
      } catch (err) {
        if (
          err.response.data.message ===
          "profile_missing_field: Please complete your profile before attempting to apply for a job."
        ) {
          toggleWarningDetails();
        } else if (
          err.response.data.message ===
          "resume_missing_field: Please upload your resume before attempting to apply for a job."
        ) {
          toggleWarningResume();
        } else if (err.response.data.message === "Already applied") {
          toggleWarningModal();
        }
        setIsLoading(false);
      }
    }
  };

  const handleConfirm = () => {
    navigate("/talent/profile");
  };
  const handleResume = () => {
    navigate("/talent/my-resume");
    window.location.reload();
  };
  const handleSuccess = () => {
    window.location.reload();
  };

  const toggleSuccessModal = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const toggleWarningModal = () => {
    setwarningModalOpen(!warningModalOpen);
  };
  const toggleWarningDetails = () => {
    setWarningDetails(!warningDetails);
  };
  const toggleWarningResume = () => {
    setWarningResume(!warningResume);
  };

  const openCustomModal = () => {
    setCustomModal(!customModal);
  };

  function isNxtClient() {
    return window.location.hostname === "www.nxt.com.ph";
  }

  function isDevClient() {
    return window.location.hostname === "dev.nxt.com.ph";
  }

  function isStagClient() {
    return window.location.hostname === "stage.nxt.com.ph";
  }

  const baseUrl = isNxtClient()
    ? "https://www.nxt.com.ph"
    : isDevClient()
    ? "https://dev.nxt.com.ph"
    : isStagClient()
    ? "https://stage.nxt.com.ph"
    : "http://localhost:3000";

  const decodedJobDescription = he.decode(jobDescription);
  const textWithoutTags = decodedJobDescription
    .toString()
    .replace(/<[^>]*>/g, "");

  return (
    <div className="joblistcontent-container">
      <MetaData
        title={jobDetails.jobTitle}
        description={textWithoutTags}
        url={`${baseUrl}/jd/${jobDetails.id}`}
        image={`${
          Config.nxtApi
        }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
          jobDetails.clientId
        )}&thumbNail=yes`}
      />

      <div className="header">
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div style={{ marginTop: "10px" }}>
              <h1>{jobDetails.jobTitle} </h1>
            </div>
          </div>

          {jobDetails.jobPostStatus === "open" && (
            <div className="socialMediaContainer">
              <SocialMediaShare
                hasInvitation={false}
                url={`${baseUrl}/share/jobs/jd/${jobDetails.job_id}`}
                subject={jobDetails.jobTitle}
                body={`JOB ID: ${jobDetails.job_id} \n\n${textWithoutTags}\n`}
              />
            </div>
          )}

          <h3>{jobDetails.companyName}</h3>
          <h3>
            {jobDetails.officeAddress} | {jobDetails.jobType} |{" "}
            {jobDetails.workArrangement}{" "}
          </h3>
          <h3>JOB ID: {jobDetails.job_id}</h3>
        </div>
        <div>
          {userType !== "client" && !isAuth && (
            <>
              <Button onClick={clickApplyJob} disabled={isApplied || isClient}>
                {isLoading ? <Loader /> : isApplied ? "Applied" : "Apply"}
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="body">
        <div className="job-details">
          <h1>Job Description</h1>
          <hr />
          <div>{Parser().parse(jobDetails.jobDescription)}</div>
        </div>
        <div className="job-details">
          <h1>Job Overview</h1>
          <hr />
          <div>{Parser().parse(jobDetails.jobOverview)}</div>
        </div>
        <div className="job-details">
          <h1>Job Qualification</h1>
          <hr />
          <div>{Parser().parse(jobDetails.qualification)}</div>
        </div>
        <div className="job-details">
          <h1>Benefits</h1>
          <hr />
          {benefit.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </div>
        <div className="job-details">
          <h1>Required Skill Set</h1>
          <hr />
          {skill.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </div>
        <div className="job-details">
          <h1>Dialect</h1>
          <hr />
          {dialect.map((dialect, index) => (
            <li key={index}>{dialect}</li>
          ))}
        </div>
      </div>

      <SuccessModal
        modalOpen={successModalOpen}
        toggleModal={toggleSuccessModal}
        iconImage={SuccessIcon}
        modalLabel="Success"
        modalSublabel="You successfully applied to this job."
        confirmButtonText="Ok"
        onConfirm={handleSuccess}
      />

      <WarningModal
        modalOpen={warningModalOpen}
        toggleModal={toggleWarningModal}
        iconImage={QuestionIcon}
        modalLabel="Warning"
        modalSublabel="Already Applied"
        confirmButtonText="Confirm"
        onConfirm={toggleWarningModal}
      />

      <WarningModal
        modalOpen={warningDetails}
        toggleModal={toggleWarningDetails}
        iconImage={WarningIcon}
        modalLabel="Warning"
        modalSublabel="Please complete your profile before attempting to apply for a job."
        confirmButtonText="Confirm"
        onConfirm={handleConfirm}
      />
      <WarningModal
        modalOpen={warningResume}
        toggleModal={toggleWarningResume}
        iconImage={WarningIcon}
        modalLabel="Warning"
        modalSublabel="Please complete your documents (CV) before attempting to apply for a job."
        confirmButtonText="Confirm"
        onConfirm={handleResume}
      />

      <CustomModal
        buttonName={buttonName}
        color={color}
        icon={icon}
        modalType={modalType}
        modalMessage={modalMessage}
        onHide={() => openCustomModal()}
        show={customModal}
        onClick={() => navigate("/talent/login")}
      />
    </div>
  );
};

export default JobListContent;
