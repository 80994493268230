import { useState } from "react";
import Config from "../Config/config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { updatefeatures } from "../services/Actions/featuresAction";

export const useSubscription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkSubsData, setCheckSubsData] = useState(null);
  const [checkSubsError, setCheckSubsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkSubscription = async (clientId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${Config.subs}/api/v1/subscription/free-trial`,
        {
          params: {
            customerId: clientId,
          },
        }
      );

      console.log(response.data.response.subscription);
      setCheckSubsData([response.data.response.subscription]);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setCheckSubsError(checkSubsError);
    }
  };

  const subscribePlan = async (data) => {
    console.log("Subs ", data);
    try {
      const response = await axios.post(
        `${Config.subs}/api/v1/subscription/order`,
        data
      );

      setIsLoading(false);
      console.log(response);
      // /admin/ats/subscription/checkout

      if (location.pathname === "/admin/ats/subscription/checkout") {
        navigate("/admin/ats/subscription/subscribed");
      } else {
        navigate("/client/subscription-subscribed");
      }

      // navigate("/client/subscription-subscribed", {
      //   state: { typeOfSub: typeOfSub },
      // });
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 504) {
        navigate("/client/subscription-subscribed");
      } else {
        alert("client is unable subscribe to ats");
        console.log(error);
      }
    }
  };

  return {
    checkSubscription,
    checkSubsData,
    checkSubsError,
    isLoading,
    subscribePlan,
  };
};
