import React from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import { localDevBase } from "../../../services/API/localDevBase";
import CheckIsSubscribe from "../../../Utilities/Reusable/Validation/Validator/CheckIsSubscribe.ats";
import { setSubscribe } from "../../../services/Actions/subscribe";
import { useSelector } from "react-redux";
import Config from "../../../Config/config";

const SubsLastPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useAuthToken();
  const devBase = localDevBase();
  const subscribe = useSelector(
    (state) => state.reducer.subscribe?.isSubscribe
  );

  const location = useLocation();
  const typeOfSub = location.state?.typeOfSub;
  const feature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withATS
  );

  const handleGoToAts = async () => {
    if (feature) {
      navigate("/admin/ats/manage-job");
    } else {
      navigate("/client/manage-job");
    }
  };

  const handleGoToSubscription = async () => {
    navigate("/client/subscription");
  };

  return (
    <>
      <Card className="text-center w-50 bg-white mx-auto my-5 p-5">
        <img
          style={{ height: "50px", width: "150px" }}
          className="mx-auto"
          alt="nxt-logo"
          src={require("../../../../src/assets/images/nxt-logo.png")}
        />
        <h4 className="mt-3 mb-2">Thank you for your purchase! </h4>
        <small>Please check your email for the next steps.</small>
        <div className={"d-flex justify-content-center gap-5 mt-5"}>
          {location.pathname === "/admin/ats/subscription/subscribed" ? (
            <>
              <Button
                variant="outline-secondary"
                className={`subscribed__btn-purple py-3 mt-4 
            }`}
                onClick={() => {
                  navigate("/admin/ats/dashboard");
                }}
              >
                Go to Dashboard
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outline-secondary"
                className={`subscribed__btn-purple py-3 mt-4 
            }`}
                onClick={handleGoToAts}
              >
                Go to Manage Jobs
              </Button>
            </>
          )}

          {typeOfSub !== "freemTrialSubs" && (
            <Button
              variant="outline-secondary"
              className="subscribed__btn-purple py-3 mt-4"
              onClick={handleGoToSubscription}
            >
              Go to Subscription
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

export default SubsLastPage;
