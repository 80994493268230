import {
  createArraySchema,
  nonEmptyStringValidator,
  stringNumberValidator,
  stringOnlyValidator,
  emailValidator,
  contactNumberValidator,
} from "../../Validator/CommonValidator";
import { z } from "zod";
import moment from "moment";
import { useSelector } from "react-redux";

const useCreateJobSchema = () => {
  const today = moment().format("YYYY-MM-DD");
  const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");

  const trimAndRemoveTags = (value) =>
    value.replace(/<\/?[^>]+(>|$)/g, "").trim();

  const daysOfValidity = useSelector(
    (state) => state.reducer.feature?.isfeatures?.subscription?.daysOfValidity
  );

  console.log(daysOfValidity);

  let jobSchema = z
    .object({
      name: nonEmptyStringValidator("Name", 1, 64),
      contactNumber: contactNumberValidator,
      email: emailValidator,
      jobTitle: stringNumberValidator("Job Title", 64),
      jobType: nonEmptyStringValidator("Job Type", 1, 64),
      city: stringOnlyValidator("City"),
      workArrangement: nonEmptyStringValidator("Work Arrangement", 1, 64),
      officeAddress: stringNumberValidator("Office Address", 64),
      minSalary: z.number({
        required_error: "Minimum Salary is required",
        invalid_type_error: "Minimum Salary is required",
      }),
      maxSalary: z.number({
        required_error: "Maximum Salary is required",
        invalid_type_error: "Maximum Salary is required",
      }),
      startTime: stringNumberValidator("Start Time", 64),
      endTime: stringNumberValidator("End Time", 64),
      jobDescription: z.string(),
      jobOverview: z.string(),
      qualification: z.string(),
      benefits: createArraySchema("Benefits"),
      skillSet: createArraySchema("Skill Set"),
      dialect: createArraySchema("Dialect"),
      startDate: z.string().nonempty("Start date cannot be empty"),
      isActive: z.boolean().optional(),
      endDate: z.string().nonempty("End date cannot be empty"),
    })
    .refine((data) => data.minSalary <= data.maxSalary, {
      message: "Minimum salary should not be higher than maximum salary",
      path: ["maxSalary"],
    })
    .refine(
      (data) => moment(data.startDate, "YYYY-MM-DD").isSameOrAfter(today),
      {
        message: "Start date cannot be in the past",
        path: ["startDate"],
      }
    )
    .refine(
      (data) => moment(data.endDate, "YYYY-MM-DD").isSameOrAfter(tomorrow),
      {
        message: "End date should be tomorrow onwards",
        path: ["endDate"],
      }
    )
    .refine((data) => data.endDate !== data.startDate, {
      message: "End date and Start Date should not be the same",
      path: ["endDate"],
    })
    .refine(
      (data) =>
        moment(data.endDate, "YYYY-MM-DD").isAfter(
          moment(data.startDate, "YYYY-MM-DD")
        ),
      {
        message: "End date should be greater than Start Date",
        path: ["endDate"],
      }
    );

  if (daysOfValidity !== 0) {
    jobSchema = jobSchema.refine(
      (data) =>
        moment(data.endDate, "YYYY-MM-DD").isBefore(
          moment(data.startDate, "YYYY-MM-DD").add(daysOfValidity, "days")
        ),
      {
        message: `End date must be within ${daysOfValidity} days of Start Date`,
        path: ["endDate"],
      }
    );
  }

  return jobSchema;
};

export default useCreateJobSchema;
