import React, { useEffect, useState } from "react";
import { Button, Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import "../../assets/scss/layout/Navbar/_adminNav.scss";
import AdminSidebar from "../Sidebar/Admin/AdminSidebar";
import Image from "../../components/UI/Image";
import moment from "moment";
import { useSelector } from "react-redux";

const AdminNav = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  // const [formattedDateTime, setFormattedDateTime] = useState("");
  const reduxFirstName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.firstName
  );

  const toggleOffcanvas = () => {
    setShowOffcanvas((prevState) => !prevState);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const formattedDateTime = currentDateTime.toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  const [companyList, setCompanyList] = useState([]);

  const data = {
    code: 0,
    result: [
      {
        ownerId: "564efb49-cc55-4859-a446-54d7b3db488d",
        companyName: "Sad Gorl Era",
        subscriptionId: "19d91462-d518-4af4-b0d0-239e2e27be77",
      },
      {
        ownerId: "564efb49-cc55-4859-a446-54d7b3db488d",
        companyName: "Acquiro Inc",
        subscriptionId: "19d91462-d518-4af4-b0d0-239e2e27be77",
      },
      {
        ownerId: "564efb49-cc55-4859-a446-54d7b3db488d",
        companyName: "Asticom",
        subscriptionId: "19d91462-d518-4af4-b0d0-239e2e27be77",
      },
    ],
    message: "ROLES FETCHED",
  };

  const companyDropdown = async () => {
    try {
      console.log(data);

      // const response = await axios.get(``, headers)
      // setCompanyList(response.data.result);
      setCompanyList(data.result);
      console.log(companyList);
    } catch (error) {
      console.log(error);
    }
  };
  const companyName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.companyName
  );
  const [selectedCompany, setSelectedCompany] = useState(companyName);
  const handleChooseCompany = (value) => {
    console.log(value);
    setSelectedCompany(value);
  };

  useEffect(() => {
    companyDropdown();
  }, [companyName]);

  return (
    <div style={{ background: "#6f64f8" }}>
      <Navbar
        expand="md"
        className="adminNav"
        bg="none"
        style={{ background: "#6f64f8" }}
      >
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-md`}
          onClick={toggleOffcanvas}
        />
        <Navbar.Brand className="date">
          Today is: <span>{formattedDateTime}</span>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end welcomeUser">
          <div className="ml-auto">
            Welcome Back! <span>{reduxFirstName}</span>
          </div>
          {/* <NavDropdown
            title={selectedCompany}
            id="basic-nav-dropdown"
            drop="down-centered"
            value="hh"
          >
            {companyList.map((val, key) => {
              return (
                <>
                  <NavDropdown.Item
                    style={{
                      color:
                        selectedCompany === val.companyName
                          ? "blue"
                          : "#000000",
                    }}
                    onClick={() => handleChooseCompany(val.companyName)}
                  >
                    {val.companyName}
                  </NavDropdown.Item>
                </>
              );
            })}
          </NavDropdown> */}
        </Navbar.Collapse>
      </Navbar>
      <Offcanvas
        show={showOffcanvas}
        onHide={() => setShowOffcanvas(false)}
        id={`offcanvasNavbar-expand-md`}
        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
            <Image
              height={54}
              width={150}
              src={require("../../assets/images/Logo/Brand/nxtDefault.png")}
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AdminSidebar />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AdminNav;
