import React, { useState, useEffect } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import axios from "axios";
import GlobalText from "../../assets/global/GlobalText.json";
import "../../assets/scss/Client/Layout/ApplicantList/ViewResume/SidebarViewResume.scss";
import { fetchApplicantData } from "../../components/LayoutClient/ApplicantList/ViewResume/fetchData";
import Loader from "../loader/Loader";
import WarningModal from "../../components/WarningModal";
import WarningIcon from "../../assets/images/WarningIcon.png";
import QuestionIcon from "../../assets/images/QuestionIcon.png";
import QuestionModal from "../../components/QuestionModal";
import { useLocation, useNavigate } from "react-router-dom";
import { faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthToken } from "../../Utilities/Auth/Token";
import { GetData } from "../../Utilities/Fetch/GetData";
import { patchAPI } from "../../services/API/patchAPI";

const SidebarViewResume = (data) => {
  const [isLoading, setIsLoading] = useState(false);

  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_CLIENT_API;
  // const getJob = JSON.parse(localStorage.getItem("jobList"));
  // console.log("Get Job :", getJob);
  const navigate = useNavigate();

  const location = useLocation();
  const jobId = location.state?.jobId;
  const talentId = location.state?.talentId;

  // const status = location.state?.status;
  // const calendar = location.state?.calendar;

  const [talentData, setTalentData] = useState([]);
  const [calendar, setCalendar] = useState(location.state?.calendar);
  const [status, setStatus] = useState(location.state?.status);
  // const [talentId, setTalentId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [applicantStatus, setApplicantStatus] = useState(null);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  // const dataIndex = 2;

  // const fetchData = async () => {
  //   try {
  //     const data = await fetchApplicantData(dataIndex, token);
  //     console.log("fetch data", data);
  //     setTalentData(data);
  //     return data;
  //   } catch (error) {
  //     console.log("Fetch applicant data error:", error);
  //     return null;
  //   }
  // };

  // const fetchApplicantStatus = async (jobId, applicantId) => {
  //   console.log("jobId ", jobId, " applicantId ", applicantId);

  //   try {
  //     const response = await GetData(
  //       `api/client/jobs/${jobId}/applicants/${applicantId}/status`,
  //       token
  //     );
  //     console.log("status", response);

  //     setApplicantStatus(response);
  //     setStatus(response.status);
  //     setCalendar(response.calendar);
  //   } catch (error) {
  //     console.log("Fetch error:", error);
  //   }
  // };

  // useEffect(() => {
  //   const jobId = getJob.jobId;

  //   fetchData().then((data) => {
  //     if (data) {
  //       fetchApplicantStatus(jobId, data.applicantId);
  //     }
  //   });
  // }, [getJob.jobId]);

  const updateApplicantStatus = async () => {
    const payload = {
      calendar: calendar,
      status: status,
    };

    try {
      const response = await patchAPI(
        `api/client/jobs/${jobId}/applicants/${talentId}/status`,
        payload,
        token
      );
      setIsLoading(false);

      navigate("/client/applicant-list");
    } catch (error) {
      console.error("Update error:", error);
    }
  };

  const handleSave = () => {
    if (calendar === "" || status === "") {
      toggleModal2(); // Show the validation modal
    } else {
      toggleModal();
    }
  };

  const handleManageClick = () => {
    setIsLoading(true);
    updateApplicantStatus(jobId, talentId, token);
  };

  return (
    <div className="sidebar-vr-container">
      <h5>Application Status</h5>
      <Input
        type="select"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        {GlobalText.statusOptions.map((option, key) => {
          return (
            <option key={key} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          );
        })}
      </Input>
      <Input
        type="select"
        value={calendar}
        onChange={(e) => setCalendar(e.target.value)}
      >
        {GlobalText.calendarOptions.map((option, key) => {
          return (
            <option key={key} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          );
        })}
      </Input>
      <Button onClick={handleSave}>Save</Button>
      <Modal
        isOpen={modalOpen}
        keyboard={false}
        className="custom-modal-container"
        centered
      >
        <ModalBody className="custom-modal-body">
          <Button
            color="link"
            className="close-button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} className="close-icon" />
          </Button>
          <div className="icon-container">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="question-icon"
            />
          </div>
          <p className="modal-title-center">
            Are you sure you want to update the status?
          </p>

          <Button
            color="primary"
            onClick={handleManageClick}
            className="question-btn"
          >
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </ModalBody>
      </Modal>

      <WarningModal
        modalOpen={modalOpen2}
        toggleModal={toggleModal2}
        iconImage={WarningIcon}
        modalLabel="Warning"
        modalSublabel="Please fill up all necessary fields"
        confirmButtonText="Confirm"
        onConfirm={toggleModal2}
      />
    </div>
  );
};

export default SidebarViewResume;
