import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { SubscriptionFunction } from "../../../../Utilities/Reusable/Function/Subscription/Subscription";
import moment from "moment";

const SubsOrderSummary = ({
  selectedPlan,
  typeOfSub,
  billing,
  expiredFreeTrial,
  existingTransaction,
  subsData,
}) => {
  const [proratedPrice, setProratedPrice] = useState(null);
  const { getPlanPrice, getPlanName, calculatePrice } = SubscriptionFunction();
  const navigate = useNavigate();
  const getProratedPrice = () => {
    if (subsData[0].activeSubscription.current !== "freemium") {
      setProratedPrice(
        calculatePrice(
          selectedPlan.amount,
          subsData[0].activeSubscription.daysLeft
        )
      );
    }
  };

  useEffect(() => {
    getProratedPrice();
  }, [billing]); // Update prorated price when billing changes

  const vatValue =
    subsData[0].activeSubscription.current !== "freemium"
      ? proratedPrice * billing * 0.12
      : selectedPlan.amount * billing * 0.12;

  const totalAmount =
    subsData[0].activeSubscription.current !== "freemium"
      ? proratedPrice * billing + vatValue
      : selectedPlan.amount * billing + vatValue;

  const formattedTotalAmount = totalAmount.toLocaleString();

  return (
    <>
      <div className="checkout-page bg-white p-4">
        <h2 className="border-bottom pb-4 mb-4">Order Summary</h2>
        <div className="checkout-premium__border p-3">
          <Row className="d-flex flex-column">
            <Col md={4}>
              <h2>{selectedPlan.planNameTxt}</h2>
              <div className="subs-features mt-3">Subscription Features:</div>
              {selectedPlan.description.map((item, index) =>
                item.isBullet ? (
                  <ul key={index}>
                    <li>{item.text}</li>
                  </ul>
                ) : (
                  <label className="pt-2 d-flex" key={index}>
                    {item.text}
                  </label>
                )
              )}
            </Col>
            <Col>
              <hr />
              {/* <div className="position-absolute top-50 end-0 translate-middle-y">
                <div className="d-flex">
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className="me-2"
                    disabled
                  >
                    <i class="fa-solid fa-trash"></i> Remove
                  </Button>{" "}
                  <Button variant="outline-secondary" size="sm" disabled>
                    <i class="fa-solid fa-ticket"></i> Apply Coupon
                  </Button>{" "}
                </div>
              </div> */}
            </Col>
            <Col>
              <div class="d-flex justify-content-end">
                ₱{" "}
                {subsData[0].activeSubscription.current !== "freemium"
                  ? (proratedPrice * billing).toLocaleString()
                  : (selectedPlan.amount * billing).toLocaleString()}
              </div>
            </Col>
          </Row>
        </div>
        <Col md={12}>
          <div className="d-flex mt-4">
            <div className="ms-auto text-end fw-bold">
              <p className="mb-0">
                SubTotal (
                {selectedPlan.planName === "ats+ai"
                  ? "3"
                  : ["ai", "ats"].includes(selectedPlan.planName)
                  ? "2"
                  : "1"}{" "}
                Item): ₱{" "}
                {subsData[0].activeSubscription.current !== "freemium"
                  ? (proratedPrice * billing).toLocaleString()
                  : (selectedPlan.amount * billing).toLocaleString()}
              </p>
              <p>
                VAT (12 %):
                {" ₱ " + vatValue.toLocaleString()}
              </p>
            </div>
          </div>
        </Col>
      </div>
      <Col md={12}>
        <div className="checkout-page__subtotal d-flex p-4">
          <div className="ms-auto text-end fw-bold">
            <p className="mb-0">
              Total :
              <span className="checkout-text__violet">
                {" "}
                {typeOfSub === "freemTrialSubs"
                  ? "FREE"
                  : `₱ ${formattedTotalAmount}`}
              </span>
            </p>
          </div>
        </div>
      </Col>
    </>
  );
};

export default SubsOrderSummary;
