import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";

const ManageJobFormPagination = ({ onPageChange, columnsPerPage, count }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(count / columnsPerPage);

  const startIndex = (currentPage - 1) * columnsPerPage + 1;
  const endIndex = Math.min(startIndex + columnsPerPage - 1, count);

  useEffect(() => {
    setCurrentPage(1);
  }, [count, columnsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  return (
    <div className="d-flex">
      <div className="align-middle">
        <small>
          Showing {startIndex} to {endIndex} of {count} entries
        </small>
      </div>
      <ButtonToolbar
        aria-label="Toolbar with button groups"
        className="ms-auto"
      >
        <Button
          className="me-2"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </Button>
        <ButtonGroup className="me-2" aria-label="Second group">
          <Button style={{ cursor: "text" }}>{currentPage}</Button>
        </ButtonGroup>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default ManageJobFormPagination;
