import React, { useEffect, useState } from "react";
import { Table, Button, Modal, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "../../../../../assets/scss/Page/Admin/ATS/UserManagement/_atsUserManagement.scss";
import UserManagementModal from "./UserManagementModal";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import axios from "axios";
import CustomModal from "../../../../../components/UI/Modal";
import { useSelector } from "react-redux";
const UserManagementTable = ({
  setShowModal,
  setModalMode,
  deleteUser,
  load,
  clickDeleteUser,
}) => {
  console.log(deleteUser);
  const [show, setShow] = useState(false);
  const [useList, setUserList] = useState([]);
  const devBase = localDevBase();
  const token = useAuthToken();
  const [userRole, setUserRole] = useState("");

  const getUser = async () => {
    try {
      const response = await axios.get(`${devBase}/api/ats/user/`, {
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        // withCredentials: true,
      });
      console.log(response);

      setUserList(response.data.result.roles);
      setUserRole(response.data.result.roleType);
      console.log(userRole);
    } catch (error) {
      console.log(error);
    }
  };

  const [roleId, setRoleId] = useState("");

  const delUser = async () => {
    console.log(roleId);
    console.log(`  Authorization: Bearer ${token}`);
    try {
      const response = await axios.delete(`${devBase}/api/ats/user/`, {
        params: {
          roleId: roleId,
        },
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        console.log(response);
        setModalMode("successDelete");
        console.log("Before deletion:", useList);
        setUserList((prevUserList) =>
          prevUserList.filter((user) => user.clientId !== roleId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [editData, setEditData] = useState([]);
  const handleEditJob = (email, clientId, roleType) => {
    console.log(clientId, roleType, email);
    setEditData({ email, clientId, roleType });
    setShow(true);
  };

  const companyName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile.email
  );
  console.log(companyName);

  useEffect(() => {
    getUser();
  }, [load]);

  const onEditUserSuccess = () => {
    setModalMode("successEdit");
    setShowModal(true);
    // setAddConfirmation(false);
    getUser();
  };

  const handleUserDeleteModal = (value) => {
    console.log(value);
    setRoleId(value);
    setModalMode("deleteUserOption");
    setShowModal(true);
  };

  useEffect(() => {
    if (clickDeleteUser) {
      delUser();
    }
  }, [clickDeleteUser]);
  return (
    <>
      <Table className="">
        <thead className="table-secondary">
          <tr>
            <th>User</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {useList.map((val, key) => {
            return (
              <>
                <tr>
                  <td>{val.email}</td>

                  <td>
                    {(userRole === "admin" && val.roleType === "recruiter") ||
                    (userRole === "owner" &&
                      val.roleType !== "owner" &&
                      val.email !== companyName) ? (
                      <>
                        <div className="d-flex">
                          <div
                            className="ats__btn-edituser me-2"
                            onClick={() =>
                              handleEditJob(
                                val.email,
                                val.clientId,
                                val.roleType
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPenSquare} />
                          </div>
                          <div
                            className="ats__btn-deleteuser"
                            onClick={() => handleUserDeleteModal(val.clientId)}
                            // onClick={() => delUser(val.clientId)}
                            // onClick={() => deleteUser(val.clientId)}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>

      <UserManagementModal
        show={show}
        close={() => setShow(false)}
        editData={editData}
        onEditUserSuccess={onEditUserSuccess}
      />
    </>
  );
};

export default UserManagementTable;
