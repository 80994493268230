import React, { useEffect, useState } from "react";
import { Table, Button, Modal, InputGroup, Form } from "react-bootstrap";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import axios from "axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserManagementSchema } from "../../../../../Utilities/Reusable/Validation/Schema/ATS/UserManagementSchema";
const UserManagementModal = ({
  editData,
  show,
  close,
  onAddUserSuccess,
  onEditUserSuccess,
  showAddModal,
  addConfirmation,
  onErrorAddModal,
}) => {
  const devBase = localDevBase();
  const token = useAuthToken();
  console.log(token);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(UserManagementSchema) });

  const [userFormData, setUserFormData] = useState([]);
  const submitUser = async (data) => {
    console.log(data);
    console.log(`  Authorization: Bearer ${token}`);

    if (editData) {
      editUser(data);
    } else {
      setUserFormData(data);
      showAddModal();
      // addUser(data);
    }
  };

  const addUser = async () => {
    close();
    try {
      const response = await axios.post(
        `${devBase}/api/ats/user/`,
        userFormData,
        {
          headers: {
            // "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
          // withCredentials: true,
        }
      );
      console.log(response);

      if (response.status === 200 || response.status === 201) {
        onAddUserSuccess();
        reset();
      }
    } catch (error) {
      console.log(error);
      if (error) {
        onErrorAddModal("alreadyExist");
      }
    }
  };

  const editUser = async (data) => {
    close();
    // console.log(data);
    console.log(`  Authorization: Bearer ${token}`);

    const payload = {
      patchOptions: {
        clientId: editData.clientId,
      },
      setOptions: {
        roleType: data.roleType,
      },
    };

    console.log(payload);
    try {
      const response = await axios.patch(`${devBase}/api/ats/user/`, payload, {
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        // withCredentials: true,
      });
      console.log(response);
      onEditUserSuccess();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  console.log(editData);

  useEffect(() => {
    if (editData) {
      setValue("email", editData.email);
      setValue("roleType", editData.roleType);
    } else {
      setValue("email", "");
      setValue("roleType", "");
    }
  }, [editData]);

  useEffect(() => {
    if (addConfirmation) {
      addUser();
    }
  }, [addConfirmation]);

  return (
    <>
      <Modal
        className="add-modal__button"
        show={show}
        onHide={() => {
          close();
          reset();
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton className="border-0 bg-white">
          <Modal.Title>{editData ? "Edit role" : "Add Role"}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(submitUser)}>
          <Modal.Body className="bg-white">
            <Form.Label htmlFor="basic-url" className="fw-bold">
              User
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                // value="admin@nxt.com.ph"
                id="basic-url"
                aria-describedby="basic-addon3"
                disabled={editData}
                {...register("email")}
                errors={errors}
              />
            </InputGroup>
            {errors?.email && <p>{errors.email.message}</p>}
            <Form.Label htmlFor="basic-url" className="fw-bold">
              Role
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              {...register("roleType")}
            >
              <option value="">Open this select menu</option>
              <option value="admin">Admin </option>
              <option value="recruiter">Recuiter</option>
            </Form.Select>
            {errors?.roleType && <p>{errors.roleType.message}</p>}
          </Modal.Body>
          <Modal.Footer className="border-0 bg-white">
            <button className="btn ats__btn-save" type="submit">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UserManagementModal;
