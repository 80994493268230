import React from "react";

const ViewingSubscription = () => {
  return (
    <div>
      <p>
        Good news! All our clients can enjoy our FREE subscription! Your
        <i>FREEmium</i> account will let you publish unlimited job postings for
        free. Here’s how you can view your subscription status.
      </p>
      <ol>
        <li>Log on to your client account</li>
        <li>Click on ‘Profile’</li>
        <li>Click on ‘Subscription’</li>
        <li>
          Type of Subscription and Validity will be displayed on the same page
        </li>
      </ol>
    </div>
  );
};

export default ViewingSubscription;
