import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import CustomOffcanvas from "../../Offcanvas/CustomOffcanvas";
import AllInformation from "../../../../pages/Admin/ATS/AllInformation/AllInformation";
import moment from "moment";
import "../../../../assets/scss/Components/UI/Table/_customAtsTable.scss";
import CustomModal from "../../Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutAndClearProfile } from "../../../../services/Actions/logoutAction";

const CustomAtsTable = ({
  columns,
  data,
  nameOrder,
  dateApplied,
  keyword,
  typeList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [allError, setAllError] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [isOCShowing, setisOCShowing] = useState(false);
  const [userData, setUserData] = useState("");

  const clickIcon = (val) => {
    setUserData(val);
    setisOCShowing(true);
  };

  const handleClose = () => setisOCShowing(false);

  useEffect(() => {
    const filterData = async () => {
      let updatedData = [...data];

      if (nameOrder === "asc") {
        updatedData = updatedData.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        );
      } else if (nameOrder === "desc") {
        updatedData = updatedData.sort((a, b) =>
          b.lastName.localeCompare(a.lastName)
        );
      }

      if (dateApplied) {
        const parsedDateApplied = moment(dateApplied).format("YYYY-MM-DD");

        updatedData = updatedData.filter(
          (item) =>
            moment(item.dateApplied).format("YYYY-MM-DD") === parsedDateApplied
        );
      }

      if (keyword && typeof keyword === "string") {
        updatedData = updatedData.filter((item) => {
          const fullName = `${item.firstName} ${item.lastName}`;
          const allStringValues = Object.values(item)
            .filter((value) => typeof value === "string")
            .join(" ");

          return (
            fullName.toLowerCase().includes(keyword.toLowerCase()) ||
            allStringValues.toLowerCase().includes(keyword.toLowerCase())
          );
        });
      }

      setFilteredData(updatedData);
    };

    filterData();
  }, [data, nameOrder, dateApplied, keyword]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleLogout = () => {
    setModalShow(false);
    setAllError("");
    dispatch(LogoutAndClearProfile());
    localStorage.clear();
    sessionStorage.clear();
    console.log("Logout ...");
    navigate("/");
  };

  console.log(allError);

  return (
    <>
      <Table className="custom_ats_table" responsive>
        <thead>
          <tr>
            {columns?.map((column) => (
              <th key={column.key} className="text-center">
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData?.length > 0 ? (
            filteredData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns?.map((column) => (
                  <td key={column.key} className="text-center">
                    {column.key === "action" ? (
                      <FontAwesomeIcon
                        className="custom_ats_table_eye_icon"
                        icon={faEye}
                        onClick={() => clickIcon(row)}
                      />
                    ) : column.key === "name" ? (
                      <>
                        {row.firstName} {row.lastName}
                      </>
                    ) : column.key === "jobPosting" ? (
                      <> {row.jobTitle}</>
                    ) : column.key === "dateApplied" ? (
                      <>{moment(row.dateApplied).format("MMMM DD, YYYY")}</>
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <CustomOffcanvas
        show={isOCShowing}
        handleClose={handleClose}
        placement="end"
        title="View Candidate"
      >
        <AllInformation
          item={userData}
          typeList={typeList}
          AllError={(error) => {
            setAllError(error);
            setModalShow(true);
          }}
        />
      </CustomOffcanvas>
      <CustomModal
        show={modalShow}
        modalMessage={
          allError === "Error"
            ? "You have been automatically logged out"
            : "Something Went Wrong"
        }
        modalType={"Error"}
        onHide={() => setModalShow(false)}
        buttonName="Confirm"
        onClick={handleLogout}
      />
    </>
  );
};

export default CustomAtsTable;
