import React from "react";
import "../../../assets/scss/Page/Admin/ATS/_subscriberCardInfo.scss";
import { Parser } from "html-to-react";
const SubscriberCardInfo = ({ subscriberData, statisticsLength }) => {
  return (
    <div className="subscriberCardInfo">
      <div className="titleContent">
        <h1>{subscriberData.jobTitle}</h1>
        <p>{Parser().parse(subscriberData.jobOverview)}</p>
      </div>
      <div className="statistics">
        <h1>Job List Statistics</h1>
        <div className="statisticsSection">
          <div className="hired">
            <h2>Hired</h2>
            <p>{statisticsLength.hiredLength}</p>
          </div>
          <div className="inprogress">
            <h2>In Progress</h2>
            <p>{statisticsLength.inProgressLength}</p>
          </div>
          <div className="dropped">
            <h2>Dropped</h2>
            <p>{statisticsLength.failedLength}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberCardInfo;
