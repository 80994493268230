import React, { useEffect, useState } from "react";
import "../../../assets/scss/Client/Layout/TalentList/TalentList.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationPin,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";

const TalentList = ({ searchValue }) => {
  const token = useAuthToken();
  const navigate = useNavigate();
  const [applicantlist, setapplicantlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const apiKey = process.env.REACT_APP_CLIENT_API;
  // const [searchValue, setSearchValue] = useState("");
  const getListData = async () => {
    // setSearchValue(searchValue);
    try {
      // const token = localStorage.getItem("clientToken");
      setIsLoading(true);
      // await axios
      //   .get(
      //     `${Config.nxtApi}/api/client/talents` +
      //       (searchValue ? "?search=" + searchValue : ""),

      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     setapplicantlist(response.data);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
      const response = await RequestMethod(
        "get",
        "nxtApi",
        `/api/client/talents` + (searchValue ? "?search=" + searchValue : ""),
        null,
        token
      );

      setapplicantlist(response.data);
    } catch (err) {
      console.log("error ", err);
    }
  };

  useEffect(() => {
    getListData();
  }, [searchValue]);

  let countJob = applicantlist.length;

  return (
    <div className="talentlist-client">
      <h6>{countJob} APPLICANT/S FOUND</h6>
      <hr className="line-seperator" />

      <div>
        {applicantlist.map((list) => {
          return (
            <div className="applicant-details-container">
              <div
                className="talent-list-default-logo"
                style={{
                  background: "#D9D9D9",
                  borderRadius: "7px",
                  width: "59.66px",
                  height: "60px",
                  display: "flex",
                  marginRight: "10px",
                }}
              >
                {list.profilePic !== null ? (
                  <img
                    crossOrigin="anonymous"
                    src={`${apiKey}/profile-pic?fileName=${encodeURIComponent(
                      list.profilePic
                    )}&thumbNail=yes`}
                    alt="logo"
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{
                        color: "#AEAEAE",
                        fontSize: "40px",
                        margin: "auto",
                      }}
                    />
                  </>
                )}
              </div>
              <div
                className="details"
                onClick={() => {
                  localStorage.setItem("jobList", JSON.stringify(list));
                  navigate(`/client/view-resume?talentId=${list.talentId}`, {
                    state: { talentId: list.talentId },
                  });
                }}
              >
                <h4>
                  {list.firstName} {list.lastName}
                </h4>
                <div className="additional-details">
                  <div className="information">
                    <div className="information-group">
                      <FontAwesomeIcon icon={faLocationPin} className="icon" />
                      <h6>{list.location}</h6>
                    </div>
                    <div className="information-group">
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      <h6>{list.email}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="pagination">
        <h6>Showing 0 to 0 of 0 entries</h6>
        <Button>Load More</Button>
      </div> */}
    </div>
  );
};

export default TalentList;
