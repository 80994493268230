import React, { useEffect, useState } from "react";
import { Alert, Button, Label } from "reactstrap";
import "../../../assets/scss/Talent/Layout/MyResume/WorkHistory.scss";
import WorkHistoryModal from "../../FormTalent/MyResume/WorkHistoryModal";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import QuestionModal from "../../QuestionModal";
import Loader from "../../../layouts/loader/Loader";
import EditWorkHistoryModal from "../../FormTalent/MyResume/EditWorkHistoryModal";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";

const WorkHistory = ({ hasAI, workHistory }) => {
  // console.log(hasAI.content.workHistory);
  console.log(workHistory, hasAI);
  const AIWorkhistory = hasAI?.workHistory || workHistory.data?.data;
  console.log(AIWorkhistory);

  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;
  const [workHistoryProfessional, setworkHistoryProfessional] = useState(
    AIWorkhistory?.map((item) => ({
      ...item,
      companyName:
        item.companyName.length > 255
          ? `${item.companyName.slice(0, 255)}`
          : item.companyName,
      jobRole:
        item.jobRole.length > 255
          ? `${item.jobRole.slice(0, 255)}`
          : item.jobRole,
    })) || []
  );

  const [workHistoryFreshGrad, setworkHistoryFreshGrad] = useState([]);
  const [employeeType, setEmployeeType] = useState("");
  const [showWorkHistoryModal, setShowWorkHistoryModal] = useState("");
  const [selectedWork, setSelectedWork] = useState(null);
  const [editWorkHistoryModal, setEditWorkHistoryModal] = useState("");
  //State variables for modal
  const [warningModalOpen, setwarningModalOpen] = useState(false);
  const [successModalOpen, setsuccessModalOpen] = useState(false);
  const [selectedWorkHistory, setSelectedWorkHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSuccess = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const clickWorkHistoryBtn = () => {
    setShowWorkHistoryModal(true);
  };

  const clickEditWorkHistory = (data) => {
    const truncatedData = {
      ...data,
      companyName:
        data.companyName.length > 255
          ? `${data.companyName.slice(0, 255)}`
          : data.companyName,
      jobRole:
        data.jobRole.length > 255
          ? `${data.jobRole.slice(0, 255)}`
          : data.jobRole,
    };

    setSelectedWork(truncatedData);
    setEditWorkHistoryModal(true);
  };

  const deleteCert = (
    id,
    companyName,
    jobLevel,
    jobRole,
    employmentType,
    workSetup,
    industry,
    startDate,
    endDate,
    createdAt,
    isPresent
  ) => {
    setSelectedWorkHistory({
      id,
      companyName,
      jobLevel,
      jobRole,
      employmentType,
      workSetup,
      industry,
      startDate,
      endDate,
      createdAt,
      isPresent,
    });
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const payload = {
        id: selectedWorkHistory.id,
        companyName: selectedWorkHistory.companyName,
        jobLevel: selectedWorkHistory.jobLevel,
        jobRole: selectedWorkHistory.jobRole,
        employmentType: selectedWorkHistory.employmentType,
        workSetup: selectedWorkHistory.workSetup,
        industry: selectedWorkHistory.industry,
        experienceDate: {
          startDate: selectedWorkHistory.startDate,
          endDate: selectedWorkHistory.endDate,
        },
        createdAt: selectedWorkHistory.createdAt,
        isDeleted: 1,
        isPresent: selectedWorkHistory.isPresent,
      };

      await axios
        .patch(`${Config.nxtApi}/api/talent/updateworkhistory`, payload, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          getWorkHistory();
          setsuccessModalOpen(false);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Error ", err);
        });
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const getWorkHistory = async () => {
    try {
      const payload = {
        numRow: 10,
        sortKeys: "desc",
      };

      const response = await RequestMethod(
        "post",
        "nxtApi",
        "/api/talent/workhistories",
        payload,
        token,
        null
      );

      // console.log(response);
      // console.log(response);
      const workHistoryWithAI = [...hasAI.workHistory, ...response.data.data];

      // Professional
      setworkHistoryFreshGrad(response.data);
      setworkHistoryProfessional(workHistoryWithAI);
      setEmployeeType(response.data.employeeType);

      // Fresh Graduate
      // setworkHistory(response.data);
      // setEmployeeType(response.data.employeeType);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (workHistory || hasAI) {
      setworkHistoryFreshGrad(workHistory.data);
      setworkHistoryProfessional(
        AIWorkhistory?.map((item) => ({
          ...item,
          companyName:
            item.companyName.length > 255
              ? `${item.companyName.slice(0, 255)}`
              : item.companyName,
          jobRole:
            item.jobRole.length > 255
              ? `${item.jobRole.slice(0, 255)}`
              : item.jobRole,
        })) ||
          [] ||
          workHistory.data.data
      );
      setEmployeeType(workHistory.data.employeeType);
    }
  }, [workHistory, hasAI]);

  console.log(workHistoryProfessional, hasAI);

  const handleSaveResult = () => {
    workHistoryProfessional.forEach((work) => {
      const {
        companyName,
        jobLevel,
        jobRole,
        employmentType,
        workSetup,
        industry,
        experienceDate,
        isPresent,
      } = work;

      const emptyProperties = [];

      if (!companyName) emptyProperties.push("companyName");
      if (!jobLevel) emptyProperties.push("jobLevel");
      if (!jobRole) emptyProperties.push("jobRole");
      if (!employmentType) emptyProperties.push("employmentType");
      if (!workSetup) emptyProperties.push("workSetup");
      if (!industry) emptyProperties.push("industry");
      if (!experienceDate?.startDate) emptyProperties.push("startDate");
      if (!experienceDate?.endDate) emptyProperties.push("endDate");
      if (isPresent === undefined) emptyProperties.push("isPresent");

      if (emptyProperties.length > 0) {
        // console.log(
        //   "Error: The following properties are empty for company:",
        //   companyName,
        //   emptyProperties.join(", ")
        // );

        console.log(
          "Please check the required details of company name",
          companyName
        );
      } else {
        console.log("Save operation successful for company:", companyName);
      }
    });
  };

  return (
    <div>
      {employeeType === "Fresh Graduate / Intern" ? (
        <>
          <div className="work-history-talent">
            <div className="header-container">
              <h6>Educational Information</h6>
            </div>
            <hr className="line-separator" />
            <div className="work-history-list-container">
              <div className="work-history-list">
                <Label>Educational Background</Label>
                <p>
                  - {workHistoryFreshGrad.studentInfo.educationalBackground}
                </p>
                <Label>Course</Label>
                <p>- {workHistoryFreshGrad.studentInfo.course}</p>
                <Label>School</Label>
                <p>- {workHistoryFreshGrad.studentInfo.school}</p>
                <Label>School Year</Label>
                <p>- {workHistoryFreshGrad.studentInfo.schoolYear}</p>
              </div>
            </div>
          </div>
          <div className="work-history-talent">
            <div className="header-container">
              <h6>WORK HISTORY</h6>
              <Button
                onClick={() => {
                  clickWorkHistoryBtn();
                }}
              >
                Add Work History
              </Button>
            </div>
            <hr className="line-separator" />
            <div className="work-history-list-container">
              {workHistoryProfessional.map((data) => (
                <div className="work-history-list" key={data.id}>
                  sdfsd
                  <Label>
                    {data.companyName} - {data.jobRole}
                  </Label>
                  <p>Job Level: {data.jobLevel}</p>
                  <p>
                    Time Period:{" "}
                    {moment(
                      data.experienceDate.startDate || data.startDate
                    ).format("MM/DD/YYYY")}{" "}
                    -
                    {data.isPresent === 0 ? (
                      "Present"
                    ) : (
                      <>
                        {moment(data.experienceDate.endDate).format(
                          "MM/DD/YYYY"
                        )}
                      </>
                    )}
                    {data.isPresent}
                  </p>
                  <div
                    className="remove-icon-container"
                    onClick={() => {
                      setsuccessModalOpen(true);
                      deleteCert(
                        data.id,
                        data.companyName,
                        data.jobLevel,
                        data.jobRole,
                        data.employmentType,
                        data.workSetup,
                        data.industry,
                        moment(data.experienceDate.startDate).format(
                          "MM-DD-YYYY"
                        ),
                        moment(data.experienceDate.endDate).format(
                          "MM-DD-YYYY"
                        ),
                        data.createdAt,
                        data.isPresent
                      );
                    }}
                  >
                    <div className="remove-work-container">
                      <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
                      <span>Remove</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : employeeType === "Professional" ? (
        <div className="work-history-talent">
          <div className="header-container">
            <h6>WORK HISTORY</h6>
            <Button
              onClick={() => {
                clickWorkHistoryBtn();
              }}
            >
              Add Work History
            </Button>
          </div>
          <hr className="line-separator" />
          <div className="work-history-list-container">
            {workHistoryProfessional.map((data) => (
              <div className="work-history-list" key={data.id}>
                <Label>
                  {data.companyName} - {data.jobRole}
                </Label>
                <p>Job Level: {data.jobLevel}</p>
                <p>
                  Time Period:{" "}
                  {moment(
                    data.experienceDate.startDate || data.startDate
                  ).format("MM/DD/YYYY")}{" "}
                  -{" "}
                  {data.isPresent === 0 ? (
                    "Present"
                  ) : (
                    <>
                      {moment(
                        data.experienceDate.endDate || data.endDate
                      ).format("MM/DD/YYYY")}
                    </>
                  )}
                  asd {data.isPresent}
                </p>
                <div
                  className="remove-icon-container"
                  onClick={() => {
                    setsuccessModalOpen(true);
                    deleteCert(
                      data.id,
                      data.companyName,
                      data.jobLevel,
                      data.jobRole,
                      data.employmentType,
                      data.workSetup,
                      data.industry,
                      moment(
                        data.experienceDate.startDate || data.startDate
                      ).format("MM-DD-YYYY"),
                      moment(
                        data.experienceDate.endDate || data.endDate
                      ).format("MM-DD-YYYY"),
                      data.createdAt,
                      data.isPresent
                    );
                  }}
                >
                  <div className="remove-work-container">
                    <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
                    <span>Remove</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="work-history-talent">
          <div className="header-container">
            <h6>Work History</h6>
            <Button
              onClick={() => {
                clickWorkHistoryBtn();
              }}
            >
              Add Work History
            </Button>
          </div>
          <hr className="line-separator" />{" "}
          <div className="work-history-list-container">
            {workHistoryProfessional?.length === 0 ? (
              <>
                <Alert color="secondary">
                  No Work History has been added yet
                </Alert>
              </>
            ) : (
              <>
                {workHistoryProfessional.map((data) => (
                  <div className="work-history-list" key={data.id}>
                    <Label>
                      {data.companyName} - {data.jobRole}
                    </Label>
                    <p>Job Level: {data.jobLevel}</p>
                    {/* <p>
                      Time Period:{" "}
                      {moment(
                        data.startDate || data.experienceDate.startDate
                      ).format("MM/DD/YYYY")}{" "}
                      -{" "}
                      {data.isPresent === 0 ? (
                        "Present"
                      ) : (
                        <>
                          {moment(
                            data.experienceDate.endDate || data.endDate
                          ).format("MM/DD/YYYY")}
                        </>
                      )}
                    </p> */}
                    <div className="remove-icon-container">
                      <div className="remove-work-container">
                        <div
                          onClick={() => {
                            setsuccessModalOpen(true);
                            deleteCert(
                              data.id,
                              data.companyName,
                              data.jobLevel,
                              data.jobRole,
                              data.employmentType,
                              data.workSetup,
                              data.industry,
                              moment(
                                data.experienceDate.startDate || data.startDate
                              ).format("MM-DD-YYYY"),
                              moment(
                                data.experienceDate.endDate || data.endDate
                              ).format("MM-DD-YYYY"),
                              data.createdAt,
                              data.isPresent
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="remove-work"
                          />
                          <span>Remove</span>
                        </div>

                        <div>
                          <Button
                            className="view-attachment-btn"
                            onClick={() => {
                              clickEditWorkHistory(data);
                            }}
                          >
                            <FontAwesomeIcon />
                            Edit Work History
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* {AIWorkhistory?.length > 0 && (
            <>
              <div className="d-flex mt-3">
                <Button className="btn btn-workhistory_cancel ms-auto me-2">
                  Cancel Result
                </Button>
                <Button
                  className="btn btn-workhistory_save"
                  onClick={handleSaveResult}
                >
                  Save Result
                </Button>
              </div>
            </>
          )} */}
        </div>
      )}
      {showWorkHistoryModal && (
        <WorkHistoryModal
          isOpen={showWorkHistoryModal}
          close={() => setShowWorkHistoryModal(false)}
        />
      )}

      {editWorkHistoryModal && (
        <EditWorkHistoryModal
          isOpen={editWorkHistoryModal}
          editHistory={selectedWork}
          close={() => setEditWorkHistoryModal(false)}
        />
      )}
      <QuestionModal
        modalOpen={successModalOpen}
        toggleModal={toggleSuccess}
        iconImage={require("../../../assets/images/QuestionIcon.png")}
        modalLabel="Warning!"
        modalSublabel="Are you sure you want to delete this work history?"
        confirmButtonText={isLoading ? <Loader /> : "Delete"}
        onConfirm={confirmDelete}
        onCancel={toggleSuccess}
      />
    </div>
  );
};

export default WorkHistory;
