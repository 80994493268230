import React, { useEffect, useState } from "react";
import axios from "axios";
import fb from "../../../assets/images/Logo/SocMed/facebook.png";
import Config from "../../../Config/config";
import { initFacebookSDK } from "../../../Utilities/SocialMedia/facebook";
import Image from "../../../components/UI/Image";
import { fetchSysparValue } from "../../../Utilities/Fetch/FetchSysparValue";
import { loginSuccess } from "../../../services/Actions/userSlice";
import { useDispatch } from "react-redux";
import Loader from "../../loader/Loader";
import { Spinner } from "react-bootstrap";
import "../../../assets/scss/Components/UI/_socialMediaIcons.scss";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";
import { getCompanyProfile } from "../../../services/Actions/companyProfileSlice";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../../services/Actions/profileSlice";
import { setfeatures } from "../../../services/Actions/featuresAction";
const FacebookLogin = ({ onError, onSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [appIdEntry, setAppIdEntry] = useState("");
  const [isFbLoaded, setIsFbLoaded] = useState(false);

  useEffect(() => {
    const loadFBSSO = async () => {
      await fetchSysparValue("FB_APP_ID", setAppIdEntry);

      // fb sso
      if (!window.FB) {
        setTimeout(async () => {
          await loadFBSSO();
        }, 10);
      } else {
        setIsFbLoaded(true);
      }
    };
    loadFBSSO();
  }, []);

  async function statusChangeCallback(response) {
    // Called with the results from FB.getLoginStatus().
    const FB = window.FB;
    if (response.status === "connected") {
      // Logged into your webpage and Facebook.
      await loginFB(
        { id: response.authResponse.userID },
        response.authResponse.signedRequest
      );
    } else {
      // Not logged into your webpage or we are unable to tell.
      //onError('Please log ' +'into this webpage.');
      const result = await new Promise((resolve) => {
        FB.login(function (response) {
          resolve(response);
        });
      });
      await statusChangeCallback(result);
    }
  }

  async function onClickLoginFB() {
    const FB = window.FB;

    FB.init({
      appId: appIdEntry,
      cookie: true, // Enable cookies to allow the server to access the session.
      xfbml: true, // Parse social plugins on this webpage.
      version: "v18.0", // Use this Graph API version for this call.
    });

    FB.getLoginStatus(function (response) {
      // Called after the JS SDK has been initialized.
      statusChangeCallback(response); // Returns the login status.
    });
  }

  const loginFB = async (response, signedRequest) => {
    const id = response.id;

    try {
      // const fbResponse = await axios.post(
      //   `${Config.nxtApi}/api/user/login/fb`,
      //   {
      //     id: id,
      //     signedRequest: signedRequest,
      //   }
      // );

      const fbResponse = await RequestMethod(
        "post",
        "nxtApi",
        "/api/user/login/fb",
        { id: id, signedRequest: signedRequest },
        null,
        null
      );
      // dispatch(loginSuccess(fbResponse.data));
      if (
        fbResponse.data.userType === "applicant" ||
        fbResponse.data.userType === "talent"
      ) {
        fetchTalentProfile(fbResponse.data.token, fbResponse.data);
      } else if (fbResponse.data.userType === "client") {
        fetchClientProfile(fbResponse.data.token, fbResponse.data);
      }
      // onSuccess(fbResponse.data.message);
    } catch (error) {
      onError(error.data.message);
      console.log("FB Login Error", error);

      const FB = window.FB;
      if (FB) {
        console.log("Logging out from Facebook...");
        FB.logout(function (response) {
          // Log out successful
          console.log("User logged out from Facebook");
        });
      }
    }
  };

  const fetchClientProfile = async (token, data) => {
    try {
      const result = await RequestMethod(
        "get",
        "nxtApi",
        "/api/client/myprofile",
        null,
        token
      );

      if (result.status === 200) {
        dispatch(getCompanyProfile(result.data.data[0]));
        dispatch(setfeatures(result.data.subscription));
        dispatch(loginSuccess(data));
        onSuccess(data.data.message);
      }

      // navigate("/client/company-profile");
      // console.log("Data:", result.data.data[0]);
      // dispatch(getProfile(result.data.data[0]));
      // dispatch(loginSuccess(data));
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  // Get Talent Profile
  const fetchTalentProfile = async (token, data) => {
    try {
      const response = await RequestMethod(
        "get",
        "nxtApi",
        "/api/talent/myprofile",
        null,
        token
      );

      if (response.status === 200) {
        dispatch(loginSuccess(data));
        dispatch(getProfile(response.data.data[0]));
        onSuccess(data.data.message);
      }

      // navigate("/talent/profile");
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  return (
    <div
      onClick={onClickLoginFB}
      className={!isFbLoaded ? "fbSpinner" : "fbLogo"}
    >
      {!isFbLoaded ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <Image src={fb} width={11.11} height={20} />
      )}
    </div>
  );
};

export default FacebookLogin;
