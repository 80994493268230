import Config from "../Config/config";
import axios from "axios";
import { useAuthToken } from "../Utilities/Auth/Token";
import { updateProfile } from "../services/Actions/profileSlice";
import { useDispatch } from "react-redux";
import { CVParsing } from "./CVParsing";
import { useState } from "react";

export const useClient = () => {
  const token = useAuthToken();
  const [viewJobData, setViewJobData] = useState([]);

  const viewJobDetails = async (id, job_id) => {
    const payload = {
      job_id: job_id,
    };
    console.log(payload);
    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/client/viewjob`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("Job Details: ", response);
      setViewJobData(response.data);
    } catch (error) {
      console.log("viewJob ", error);
    }
  };

  return { viewJobDetails, viewJobData };
};
